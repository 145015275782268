Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.validationApiContentType = "application/json";
exports.apiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PhoneLogin";
exports.labelBodyText = "PhoneLogin Body";
exports.btnExampleTitle = "CLICK ME";
exports.signUpLabel = "Signup";
exports.signUpAs = "SIGNUP AS";
exports.labelIamUser = "I am a user";
exports.labelIamShoOwner = "I am a service provider";
exports.labelPhoneNumber = "PHONE NUMBER";
exports.labelLoginContent = "How would you like to continue in with us? As a user you can safely queue up your docs and find nearby printer to print them.";
exports.labelEmail = "EMAIL";
exports.labelYourPasscode = "YOUR 6 DIGIT PASSCODE";
exports.labelConfirmPasscode = "CONFIRM PASSCODE";
exports.labelIagree = "I agree to ";
exports.labelTerms = "Terms & Conditions";
exports.labelTermCondition = "I agree to Terms & Conditions";
exports.btnNextTitle = "Next";
exports.labelbtnSave = "Save";
exports.alreadyHaveAccount = "Already have an account?";
exports.errorBlankPasscode = "Enter your 6 digit passcode";
exports.errorBlankConfirmPasscode = "Enter your confirm passcode";
exports.labelCountry = "COUNTRY";
exports.labelState = "STATE";



//API CONSTANTS
exports.getOTPAPiEndPoint = "bx_block_twofactorauthentication/send_otps"
exports.verifyOTPAPiEndPoint = "account_block/accounts/sms_confirmations"
exports.loginAPiEndPoint = "bx_block_login/logins"
exports.customerSignUpAPiEndPoint = "account_block/accounts"
exports.forgotPasscodeGetOTPAPiEndPoint = "bx_block_forgot_password/otps"
exports.forgotPasscodeVerifyOTPAPiEndPoint = "bx_block_forgot_password/otp_confirmations"
exports.fpUpdatePasscodeAPiEndPoint = "bx_block_forgot_password/passwords"
exports.updateBusinessDetailsAPiEndPoint = "account_block/accounts/merchants"
exports.updatePayoutDetailsAPiEndPoint = "account_block/accounts/bank_details"
exports.privacyPolicyAPIEndPoint = "bx_block_profile/settings/privacy_policies";
exports.postAPiEndMethod = "POST"
exports.getAPiEndMethod = "GET"

exports.errorBlankField = "can't be blank";
exports.errorOTPNotValid = "Enter valid 4 digit OTP";
exports.errorMobileNoNotValid = "Phone number is not valid. Please enter valid 10 digit Phone Number ";
exports.errorPasscodeNotValid = "Passcode is not valid. Please enter valid 6 digit Passcode ";
exports.errorTitle = "Error";
exports.errorPasscodeNotMatched = "Passcode and confirmed passcode not matched.";

// user type selection
exports.labelEnterPhone = "Enter your phone number then we\nwill send OTP to verify you";
exports.labelDontHaveAccount = "Don't have an account?"
exports.labelSingUp = "Sign Up"
exports.labelUserBtn = "I AM A USER"
exports.labelShopOwnerBtn = "I AM A SHOP OWNER"
//user type selection

//verify otp
exports.labelVerifyOtp = "Verify OTP";
exports.labelOtp = "OTP";
exports.labelVerifyOtpMessage = "We have sent an one time password to your phone number ";
exports.labelForgotPasscodeMessage = "We'll send an OTP to your registered mobile\nnumber for verification";
exports.labelDidntGotOtp = "Didn't get the OTP?";
exports.labelOtpresendIn = " Resend In 02:00"
//verify otp

//signup
exports.labelAlert = "Alert"
exports.labelSingUp = "Sign Up"
exports.labelEmail = "EMAIL"
exports.errorEmailNotValid = "Enter Valid Email.";
exports.errorEmptyState = "Select State.";
exports.errorNotValid = " not valid.";
exports.signUpAPIEndPoint = "account_block/accounts"
exports.errorTermsAndCondition = "Please accept terms & condition";
//signup

//forgotPasscode
exports.labelForgotPasscode = "Forgot Passcode?"
exports.labelForgotPasscodeMsg = "We'll send an OTP to your registered mobile\nnumber for verification"
exports.sendOTPAPIEndPoint = "bx_block_twofactorauthentication/send_otps"
//forgotPasscode

//signin
exports.labelTitle = "Log in";
exports.labelGetStared = "Get Started";
exports.labelLogin = "Login";
exports.labelLoginAs = "LOGIN AS";
exports.labelIAmUser = "I am a user";
exports.labelIamShopOwner = "I am a shop owner";
exports.labelEnterYourPhone = "Enter your phone number and six digit passcode to login";
exports.labelPhoneNumber = "PHONE NUMBER";
exports.labelEnterPasscode = "ENTER PASSCODE FOR LOGIN";
exports.labelForgotPassword = "Forgot Passcode?";
exports.labelbtnNext = "Next";
exports.labelWhyEmail = "Why Email!!!";
exports.labelWhyEmailContent = "Update Email ID for better communication.";
exports.labeTermsContent = "Terms & Conditions content goes here";
exports.labelClose = "CLOSE";
exports.labelbtnCreateAccount = "Create an account";
exports.labeltooltip1 = "Data security";
exports.labeltooltip2 = "Data encryption";
exports.labeltooltip3 = "Multi-Factor Auth";
exports.labeltooltip4 = "Doc encryption";
exports.labeltooltip5 = "Secure Download";
exports.labeltooltip6 = "Docs delivery";

exports.termsAndConditionsAPIEndPoint = "bx_block_profile/settings/terms_and_conditions" //{userid}
//signin