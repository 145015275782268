//@ts-nocheck
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";

// Customizable Area Start
import StorageProvider from '../../../framework/src/StorageProvider';
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps & {
  id: string;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  id: string;
  authToken: string;
  loading: boolean;
  dataSource: [];
  dataSources: [];
  showFilter: boolean;
  selectedTag: string;
  selectedOrderStatus: string;
  isVisibleDropDown: boolean;
  selectedRangeTag: string;
  startDate: string;
  endDate: string;
  showDatePicker: boolean;
  selectedFrom: boolean;
  paymentModal: boolean;
  allShopsModal: boolean;
  totalPages: number;
  expiryTime: number;
  // Customizable Area End
}

interface SS {
  id: any;
}
let pageNo = 0;
export default class UserOrderHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetOrderHistoryCallId: String = "";
  apiUpdateOrderStatusCallId: string = "";
  apiGetCurrentOrdersCallId: string = "";
  apiGetExpiryTimeCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      id: "",
      authToken: "",
      loading: false,
      dataSource: [],
      dataSources: [],
      showFilter: false,
      selectedTag: "",
      selectedOrderStatus: "all",
      isVisibleDropDown: false,
      selectedRangeTag: "",
      startDate: "",
      endDate: "",
      showDatePicker: false,
      selectedFrom: false,
      allShopsModal: false,
      paymentModal: false,
      totalPages: 1,
      expiryTime: 4
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    //   runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      // this.parseApiErrorResponse(errorReponse);
      if (apiRequestCallId === this.apiGetOrderHistoryCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          await this.setState({ dataSource: responseJson.data });
        } else if (responseJson && responseJson.message) {
          await this.setState({ dataSource: [] });
          this.props.showNotification(responseJson.message, 'error');
        }
        else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          const validateUserError = JSON.stringify(responseJson.errors).replace(/[\[\]"]+/g, "");
          const accountBlockValidate = responseJson.errors[0].message;
          if (validateUserError === "Record not found" || accountBlockValidate === "Your Account is blocked. Please connect to administration" || accountBlockValidate === "Session Expired") {
            // this.props.showNotification("Account not found", "error");
            setTimeout(() => {
              this.props.history.push('/PhoneLoginWeb');
            }, 1000);
          }
        }
      }

      // Get current Qr code orders
      if (apiRequestCallId === this.apiGetCurrentOrdersCallId) {
        await this.setState({ loading: false });
        if (responseJson && responseJson.data) {
          setTimeout(() => {
            if (responseJson.data.length == 0 && pageNo == 1) {
              this.props.showNotification("No order for this user", 'error');
            }
          }, 200);

          if (pageNo == 1) {
            await this.setState({ dataSources: [] });
            await this.setState({ dataSources: responseJson.data });
            await this.setState({
              totalPages: responseJson.meta.page.total_pages,
            });
          } else {
            await this.setState({
              dataSources: [...this.state.dataSources, ...responseJson.data],
            });
          }
        } else if (responseJson && responseJson.message) {
          await this.setState({ dataSources: [] });
          setTimeout(() => {
            this.props.showNotification(responseJson.message, 'error');
          }, 200);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          const validateUserError = JSON.stringify(responseJson.errors).replace(/[\[\]"]+/g, "");
          const accountBlockValidate = responseJson.errors[0].message;
          if (validateUserError === "Record not found" || accountBlockValidate === "Your Account is blocked. Please connect to administration" || accountBlockValidate === "Session Expired") {
            // this.props.showNotification("Account not found", "error");
            setTimeout(() => {
              this.props.history.push('/PhoneLoginWeb');
            }, 1000);
          }
        }
      }

      if (apiRequestCallId === this.apiUpdateOrderStatusCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.props.showNotification(
            "Order Status has been updated to Cancelled",
            "success"
          );
          this.getCurrentOrders();
        } else if (responseJson && responseJson.message) {
          this.props.showNotification(responseJson.message, 'error');
        }
        else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          const validateUserError = JSON.stringify(responseJson.errors).replace(/[\[\]"]+/g, "");
          const accountBlockValidate = responseJson.errors[0].message;
          if (validateUserError === "Record not found" || accountBlockValidate === "Your Account is blocked. Please connect to administration" || accountBlockValidate === "Session Expired") {
            // this.props.showNotification("Account not found", "error");
            setTimeout(() => {
              this.props.history.push('/PhoneLoginWeb');
            }, 1000);
          } else {
            this.props.showNotification(responseJson?.errors[0]?.account || "Order is already in progress, can not cancel this order", 'error');
          }
        }
      }
      if (apiRequestCallId === this.apiGetExpiryTimeCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          await this.setState({ expiryTime: responseJson?.data?.attributes.expire_time });
        }
        else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  modalShowHideProps = {
    onPress: () => {
      this.setState({ showFilter: !this.state.showFilter });
    }
  };



  // Customizable Area Start

  async getCurrentOrders(isFiltered: boolean) {
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

    };

    this.setState({ loading: true });
    this.props.showLoader();


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOrderHistoryCallId = requestMessage.messageId;

    let getOrderHistoryAPIEndPoint = (isFiltered ? (this.state.selectedOrderStatus == 'all') ? (configJSON.getCurrentOrdersAPIEndPoint) : ('' + configJSON.getCurrentOrdersAPIEndPoint + '?filter[status]=' + `["${this.state.selectedOrderStatus}"]`) : (configJSON.getCurrentOrdersAPIEndPoint));

    if (isFiltered) {
      if (this.state.selectedOrderStatus == 'all') {
        if (this.state.startDate.length > 0 && this.state.endDate.length > 0) {
          getOrderHistoryAPIEndPoint = `${getOrderHistoryAPIEndPoint}` + "?filter[time_from]=" + this.state.startDate + '&filter[time_to]=' + this.state.endDate
        }
      } else {
        if (this.state.startDate.length > 0 && this.state.endDate.length > 0) {
          getOrderHistoryAPIEndPoint = `${getOrderHistoryAPIEndPoint}` + "&filter[time_from]=" + this.state.startDate + '&filter[time_to]=' + this.state.endDate
        }
      }
    } else {
      if (this.state.startDate.length > 0 && this.state.endDate.length > 0) {
        getOrderHistoryAPIEndPoint = `${getOrderHistoryAPIEndPoint}` + "?filter[time_from]=" + this.state.startDate + '&filter[time_to]=' + this.state.endDate
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), getOrderHistoryAPIEndPoint)


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // Get current QR code orders

  async getCurrentQrCodeOrders(isSetPageAsZero: boolean) {
    if (isSetPageAsZero) {
      pageNo = 0;
    }
    if (pageNo < this.state.totalPages) {
      pageNo = pageNo + 1;
    } else {
      return;
    }

    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCurrentOrdersCallId = requestMessage.messageId;

    let currentApiEndPoint =
      "" +
      configJSON.getCurrentQrcodeOrderAPIEndpPoint +
      "?filter[status]=Pending&pagination[page]=" +
      pageNo +
      "&pagination[per_page]=10";

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      currentApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  CancelOrder = async (orderId) => {
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

    };

    this.setState({ loading: true });
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateOrderStatusCallId = requestMessage.messageId;

    let getOrderDetailsAPIEndPoint = ('' + configJSON.getOrderDetailsAPIEndPoint).split('orderId').join('' + orderId)


    getOrderDetailsAPIEndPoint = getOrderDetailsAPIEndPoint + "?data[status]=Cancelled";

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), getOrderDetailsAPIEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  async getExpiryTime() {

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

    };

    this.setState({ loading: true });
    this.props.showLoader();


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetExpiryTimeCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getExpiryTimeAPIEndPoint)


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}
