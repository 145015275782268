//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
let config = require('../../../framework/src/config');
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps & {
  // Customizable Area Start
  id: string;
  authToken: string;
  filePath: any,
  fileName: any;
  fileType: any;
  imageUri: any,
  openDialog: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;

  // Customizable Area End
}
export type attachment = {
  id: number;
  account_id: number;
  colour: string;
  layout: string;
  page_size: string;
  //scale: string;
  print_sides: string;
  number_of_copies: number;
  print_pages_from: string;
  print_pages_to: string;
  total_pages: null,
  is_expired: boolean,
  attachment_path: string;
  content_type: string,
  file_name: string

}
export type attachmentList = {
  id: String,
  type: String;
  attributes: attachment;
}

interface S {
  // Customizable Area Start
  text: any,
  loading: boolean;
  uploading: number;
  id: string;
  selectedFileList: attachmentList[],
  uploadedFiles: File[],
  attachmentIds: Array<string>;
  authToken: string;
  pagePropertiesModal: boolean,
  pagePropertiesForSelectedFile: attachmentList | null;
  pageFromError: string;
  listOfPaperType: any;
  selectedPaperType: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PagePrintSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiUpdatePrintPropertiesCallId: String = "";
  apiDeletePrintPropertiesCallId: String = ""
  apiPlaceOrderCallId: String = ""
  apiGetAttachmentCallId: String = "";
  apiListOfPaperTypeCallId: String = "";
  apiDeleteAllPrintPropertiesCallId: String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),

    ]
    // Customizable Area Start
    this.state = {
      text: true,
      loading: false,
      id: "",
      authToken: "",
      colorActive: true,
      pagePropertiesModal: false,
      selectedFileList: [],
      uploadedFiles: [],
      attachmentIds: [],
      pagePropertiesForSelectedFile: null,
      uploading: 0,
      continueModal: false,
      pageFromError: "",
      listOfPaperType: [],
      selectedPaperType: "Normal"
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiUpdatePrintPropertiesCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.meta) {
          if (responseJson && responseJson.data) {
            this.props.showNotification(responseJson.meta.message, 'success');
            const selectedFileList = [...this.state.selectedFileList];
            const data = Array.isArray(responseJson.data) ? responseJson.data : [responseJson.data];
            for (let index = 0; index < data.length; index++) {
              const newFile = data[index];
              const newSelectedFileIndex = selectedFileList.findIndex((file) => {
                return file.id == newFile.id
              });
              let newSelectedFile = selectedFileList[newSelectedFileIndex];
              newSelectedFile = { ...newSelectedFile, attributes: { ...newFile.attributes } };
              selectedFileList[newSelectedFileIndex] = newSelectedFile;
            }
            this.setState({ selectedFileList: selectedFileList }, async () => {
              await StorageProvider.remove('selectedFiles')
              await StorageProvider.set('selectedFiles', JSON.stringify(this.state.selectedFileList));
            });
          }

        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          if (responseJson && !responseJson.error) {
            const isAll = isAll
            this.updatePrintProperties(isAll);
          } else {

            if (responseJson?.errors && responseJson.errors[0]?.message == 'Session Expired') {
              this.props.showNotification("Session Expired", "error")
              this.props.history.push('/PhoneLoginWeb')
            }

          }
          this.props.showNotification("Some Error occured", "error")
          this.sendAPIFailMessage();
        }
      } else if (this.apiDeletePrintPropertiesCallId === apiRequestCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.meta) {
          this.props.hideLoader();
          this.props.showNotification("Attachment deleted successfully", "success")
          const selectedFileList = [...this.state.selectedFileList];
          const newSelectedFileList = selectedFileList.filter((file) => { return file.id != this.attachmentId });
          this.setState({ selectedFileList: newSelectedFileList }, async () => {
            await StorageProvider.remove('selectedFiles')
            await StorageProvider.set('selectedFiles', JSON.stringify(this.state.selectedFileList));
          })
          this.getAttachmentsAPICall()
          this.props.showNotification(responseJson.meta.message, 'success');
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          this.sendAPIFailMessage();
        }
      }
      else if (this.apiDeleteAllPrintPropertiesCallId === apiRequestCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.meta) {
          this.props.hideLoader();
          this.props.showNotification("Attachments deleted successfully", "success")
          const selectedFileList = [];
          this.setState({ selectedFileList: selectedFileList }, async () => {
            await StorageProvider.remove('selectedFiles')
            await StorageProvider.set('selectedFiles', JSON.stringify(this.state.selectedFileList));
          })
          this.getAttachmentsAPICall()
          this.props.showNotification(responseJson.meta.message, 'success');
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          this.sendAPIFailMessage();
        }
      }
      else if (apiRequestCallId === this.apiPlaceOrderCallId) {
        this.setState({ loading: false })
        if (responseJson && responseJson.data && responseJson.data.id) {
          let data: any = {}
          data.orderId = responseJson?.data?.id;
          data.goBack = false;
          await StorageProvider.remove('orderId');
          await StorageProvider.remove('orderIdDetails')
          await StorageProvider.set('orderIdDetails', JSON.stringify(responseJson.data))
          await StorageProvider.set('orderId', JSON.stringify(data));
          this.props.hideLoader();
          this.props.showNotification(responseJson?.meta?.message, 'success');
          this.props.history.push('/OrderDetailsQR');

        }
      }
      else if (apiRequestCallId === this.apiGetAttachmentCallId) {
        await this.setState({ loading: false });
        if (responseJson && responseJson.data) {
          this.setState({ DataResponce: responseJson.data, selectedFileList: responseJson.data });

          this.setState({ selectedFileList: responseJson.data }, async () => {
            await StorageProvider.remove('selectedFiles')
            await StorageProvider.set('selectedFiles', JSON.stringify(this.state.selectedFileList));
          })

        } else {
          this.parseApiErrorResponse(responseJson);
          const validateUserError = JSON.stringify(responseJson.errors).replace(/[\[\]"]+/g, "");
          if (validateUserError === "Record not found") {
            setTimeout(() => {
              this.props.history.push('/PhoneLoginWeb');
            }, 1000);
          }
          this.sendAPIFailMessage();
        }
      }
      else if (apiRequestCallId === this.apiListOfPaperTypeCallId) {
        this.setState({ loading: false })
        if (responseJson) {
          this.props.hideLoader();
          this.setState({
            listOfPaperType: responseJson
          })
        }
      }
    }
    // Customizable Area End
  }

  setDefaultPropertiesForFile(fileList: any[]) {
    const attributes = {
      colour: "greyscale",
      layout: "potrait",
      page_size: "A4",
      //scale: "Print all pages",
      print_sides: "One",
      number_of_copies: 1,
      print_pages_from: 0,
      print_pages_to: 0,
    }
    for (let i = 0; i < fileList.length; i++) {
      fileList[i] = { ...fileList[i], attributes: { ...fileList[i].attributes, ...attributes } }
    }
    return fileList;
  }

  async getPaperTypes() {

    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiListOfPaperTypeCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getListOfPaperTypeAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getAttachmentsAPICall() {

    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAttachmentCallId = requestMessage.messageId;

    let finalUrl = ("" + configJSON.getUploadFilesEndPoint)
      .split("account_id")
      .join(this.state.id);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  deleteAttachMent = async (attachmentId) => {
    const header = {
      "token": this.state.authToken,
      "Content-Type": configJSON.contentType,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.props.showLoader();
    this.apiDeletePrintPropertiesCallId = requestMessage.messageId;
    this.attachmentId = attachmentId;
    let deletePrintPropertiesEndPoint = ('' + configJSON.deleteAPiEndPoint).split('attachment_id').join('' + attachmentId);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      deletePrintPropertiesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteAllAttachMents = async () => {

    let selectedFiles = await StorageProvider.get("selectedFiles")
    const selectedAttachment = selectedFiles && JSON.parse(selectedFiles);

    const header = {
      "token": this.state.authToken,
      "Content-Type": configJSON.contentType,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.props.showLoader();
    this.apiDeleteAllPrintPropertiesCallId = requestMessage.messageId;
    let deletePrintPropertiesEndPoint = configJSON.deleteAllAPiEndPoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${deletePrintPropertiesEndPoint}?attachment_ids=[${selectedAttachment.map(x => {
        return x.id
      })}]`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async updatePrintProperties(isAll: boolean) {
    if (this.state.pagePropertiesForSelectedFile?.attributes.number_of_copies === undefined || this.state.pagePropertiesForSelectedFile?.attributes.number_of_copies === 0) {
      this.props.showNotification('Please enter valid number of copies', 'error');
      return false
    }

    if (this.state.pagePropertiesForSelectedFile?.attributes.print_pages_to === undefined || this.state.pagePropertiesForSelectedFile?.attributes.print_pages_to === 0) {
      this.props.showNotification('Please enter valid print pages till', 'error');
      return false
    }

    if (this.state.pagePropertiesForSelectedFile?.attributes.print_pages_from === undefined || this.state.pagePropertiesForSelectedFile?.attributes.print_pages_from === 0) {
      this.props.showNotification('Please enter valid print pages till', 'error');
      return false
    }

    const formData = new FormData();
    let totalPage: any = (Number(this.state.pagePropertiesForSelectedFile?.attributes.print_pages_to) - Number(this.state.pagePropertiesForSelectedFile?.attributes.print_pages_from) + 1)

    formData.append('token', this.state.authToken);
    formData.append('data[account_id]', this.state.id);
    formData.append('attachment_data[colour]', this.state.pagePropertiesForSelectedFile?.attributes.colour);
    formData.append('attachment_data[layout]', this.state.pagePropertiesForSelectedFile?.attributes.layout);
    formData.append('attachment_data[page_size]', this.state.pagePropertiesForSelectedFile?.attributes.page_size);
    //formData.append('attachment_data[scale]', this.state.pagePropertiesForSelectedFile?.attributes.scale);
    formData.append('attachment_data[print_sides]', this.state.pagePropertiesForSelectedFile?.attributes.print_sides);
    formData.append('attachment_data[number_of_copies]', this.state.pagePropertiesForSelectedFile?.attributes.number_of_copies);
    formData.append('attachment_data[print_pages_to]', this.state.pagePropertiesForSelectedFile?.attributes.print_pages_to);
    formData.append('attachment_data[print_pages_from]', this.state.pagePropertiesForSelectedFile?.attributes.print_pages_from);
    formData.append('attachment_data[total_pages]', (totalPage));
    formData.append("attachment_data[total_doc]", this.state.docsCount.length || this.state.selectedFileList.length);
    formData.append('attachment_data[paper_type]', this.state.selectedPaperType || "Normal");

    if (isAll) {
      formData.append("attachment_data[set_all]", "1");
    }
    this.setState({ loading: true });
    this.props.showLoader();

    const header = {
      "accept": configJSON.contentType,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""
    };


    let savePrintPropertiesEndPoint = ('' + configJSON.savePrintPropertiesEndPoint).split('attachment_id').join('' + this.state.pagePropertiesForSelectedFile?.attributes.id);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdatePrintPropertiesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      savePrintPropertiesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async createOrder() {
    let selectedFiles = await StorageProvider.get("selectedFiles")
    const selectedAttachment = selectedFiles && JSON.parse(selectedFiles);
    let attachmentIds = []
    if (selectedAttachment) {
      attachmentIds = selectedAttachment.map(
        (attachment) => attachment.id
      );
    }
    this.setState({
      attachmentIds: attachmentIds
    }, async () => {
      const header = {
        "Content-Type": configJSON.contentType,
        "token": this.state.authToken,
        uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""
      };
      const data = {
        account_id: Number(this.state.id),
        status: 'pending',
        total_docs: Number((this.state.attachmentIds).length),
        attachment_ids: this.state.attachmentIds
      };
      const httpBody = {
        data: data
      };
      this.setState({ loading: true });

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiPlaceOrderCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.placeOrderAPiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }
  async uploadFile() {
    let formData = new FormData();
    formData.append("token", this.state.authToken);
    formData.append("data[account_id]", this.state.id);
    if (this.state.uploadedFiles.length > 0) {
      for (let i = 0; i < this.state.uploadedFiles.length; i++) {
        const fileType = this.state.uploadedFiles[i].type;
        if (fileType !== 'application/msword' && fileType !== 'application/vnd.oasis.opendocument.text' && fileType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
          fileType !== 'image/gif' && fileType !== 'image/png' && fileType !== 'image/jpg' && fileType !== 'image/jpeg' && fileType !== 'application/pdf'
          && fileType !== 'text/plain' && fileType !== 'application/vnd.ms-powerpoint' && fileType !== 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
          && fileType !== 'application/vnd.oasis.opendocument.presentation' && fileType !== 'application/vnd.ms-excel' && fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          && fileType !== 'application/vnd.oasis.opendocument.spreadsheet') {
          const { showNotification } = this.props;
          showNotification("Please upload valid file", "error");
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
          return false;
        }
        formData.append(
          `data[attachments][${i}][attachment]`,
          this.state.uploadedFiles[i]
        );
      }
    }
    this.props.showLoader();
    const baseURL = config.baseURL;
    let request = new XMLHttpRequest();
    request.open("POST", baseURL + "/" + configJSON.uploadFilesEndPoint);
    request.setRequestHeader("accept", configJSON.contentType);
    if (await StorageProvider.get('login_type') === 'guest_account') {
      request.setRequestHeader("uuid", this.state.authToken);
    }
    request.upload.addEventListener(
      "loadstart",
      () => {
        this.props.showLoader();
        this.setState({ uploading: 0 });
      }
    );
    request.upload.addEventListener(
      "progress",
      (e) => {
        if (e.lengthComputable) {
          var percentage = Math.round((e.loaded / e.total) * 100);
          this.setState({ uploading: percentage });
        }
      }
    );
    request.addEventListener(
      "load",
      async function (e) {
        this.props.hideLoader();
        /* throttledProcess is a function that does your processing spaced by the given interval millisecond */
        const throttledProcess = (items, interval) => {
          if (items.length == 0) { // stop when there's no more items to process
            return
          }
          this.props.showNotification(items[0], 'error')
          setTimeout(() => throttledProcess(items.slice(1), interval), // wrap in an arrow function to defer evaluation
            interval)
        }
        if (request.status < 400) {
          this.props.showNotification("File successfully uploaded", "success")
          let message = JSON.parse(e?.currentTarget?.response)
          if (message?.meta?.errors?.length > 0) {
            setTimeout(() => {
              throttledProcess(message?.meta?.errors, 3000)
            }, 3000)
          }
        } else {
          let errorMessage = JSON.parse(e?.currentTarget?.response)
          if (errorMessage?.errors.length > 0) {
            throttledProcess(errorMessage?.errors, 3000)
          }
        }
        let data = JSON.parse(request.response)?.data || [];
        let newSelectedFiles = [...data, ...this.state.selectedFileList];
        this.setState({ selectedFileList: newSelectedFiles, uploadedFiles: [] }, async () => {
          await StorageProvider.remove('selectedFiles')
          await StorageProvider.set('selectedFiles', JSON.stringify(this.state.selectedFileList));
        })
        await this.getAttachmentsAPICall();
      }.bind(this)
    );
    request.send(formData);
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }


}