import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from '../../../components/src/AppFonts';

export type DropZoneProps = {
  onSelectFile: (Files: FileList) => {},
  multiple: boolean,
  classes: any
}
class DropZone extends React.Component<DropZoneProps> {
  fileInputRef = React.createRef<HTMLInputElement>();
  dragOver(e: { preventDefault: () => void }) {
    e.preventDefault();
  }

  dragEnter(e: { preventDefault: () => void }) {
    e.preventDefault();
  }

  dragLeave(e: { preventDefault: () => void }) {
    e.preventDefault();
  }

  fileDrop(e: { preventDefault: () => void; dataTransfer: { files: any } }) {
    e.preventDefault();
    const files = e.dataTransfer.files;
    this.handleFiles(files);
    console.log(files);
  }


  fileInputClicked() {
    this.fileInputRef?.current?.click();
  }

  filesSelected(file: any) {
    if (this.fileInputRef?.current?.files?.length) {
      this.handleFiles(this.fileInputRef?.current?.files);
    }
  }

  handleFiles(files: FileList) {
    this.props.onSelectFile(files);
  }

  validateFile(file: any) {
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.xlsx|\.xls|\.csv|\.ppt|\.pptx|\.svg|\.doc|\.docx)$/i;
    if (!allowedExtensions.exec(file.name)) {
      return false;
    }
    return true;
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <div
          className={classes.DropContainer}
          onClick={this.fileInputClicked.bind(this)}
          onDragOver={this.dragOver.bind(this)}
          onDragEnter={this.dragEnter.bind(this)}
          onDragLeave={this.dragLeave.bind(this)}
          onDrop={this.fileDrop.bind(this)}
        >
          <div className={classes.dropMessage}>
            <div className={classes.uploadIcon} ></div>
            Drop your files, documents or images here
          </div>
        </div>
        <input
          ref={this.fileInputRef}
          className={classes.fileInput}
          type="file"
          accept=".jpg, .jpeg, .png, .gif, .xlsx, .xls, .csv, .ppt, .pptx, .svg, .doc, .docx"
          multiple={this.props.multiple}
          onChange={this.filesSelected.bind(this)}
        />
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    DropContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "auto",
      width: "80%",
      // height: "185px",
      marginTop: '20px',
      border: "2px dotted #77c285",
      flexDirection: "column",
      borderRadius: '12px',
      [theme.breakpoints.down("xs")]: {
        padding: '10px',
      },
      [theme.breakpoints.between("sm", "md")]: {
        padding: '20px 10px',
      },
      "@media (min-width: 1280px)": {
        padding: '20px 10px',
      }
    },
    uploadIcon: {
      [theme.breakpoints.down("xs")]: {
        width: "100px",
        height: "100px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "130px",
        height: "130px",
      },
      "@media (min-width: 1280px)": {
        width: "130px",
        height: "130px",
      },
      background: `url(${require('../assets/fileDoc.png')}) no-repeat center center`,
      backgroundSize: "100%",
      textAlign: "center",
      margin: "0 auto",
      paddingTop: "30px",
    },
    dropMessage: {
      textAlign: "center",
      
      [theme.breakpoints.down("xs")]: {
        width: '80%',
        margin: '0px',
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        height: '70px'
    
      },
      [theme.breakpoints.between("sm", "md")]: {
        margin: "20px auto",
        fontSize: 22,
        width: '80%',
      },
      "@media (min-width: 1280px)": {
        margin: "20px auto",
        fontSize: 22,
        width: '80%',
      },
      margin: "20px auto",
      fontWeight: 'normal',
      fontFamily: APP_FONTS.SofiaProRegular,
      color: '#1e1f20'
    },
    fileInput: {
      display: 'none'
    }
  });
export default withStyles(styles, { withTheme: true })(DropZone);