// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");
import { RouterProps } from "react-router";
export type Props = RouterProps & {
  id: string;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  phoneNumber: any;
  currentCountryCode: any;
  loading: boolean;
  id: string;
  OTP: string;
  authToken: string;
  counter: number;
  restartTimer: boolean;
  email: any
  emailOtp: any
  isMobileOtpValid: any
  isEmailOtpValid: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ChangePhoneOTPVerifyController extends BlockComponent<
  Props,
  S,
  SS
> {

  labelPhoneNumber: string;
  labelSaveBtn: string;
  labelLogin: string;
  labelEnterPhone: string;
  labelDontHaveAccount: string;
  defaultCountryCode: String;
  labelSingUp: string;
  labelDidntGotOtp: string;
  phoneReg: RegExp;
  apiType: string;
  attrs: {};
  apiGetOTPCallId: String = "";
  apiVerifyOTPCallId: String = "";
  apiChangePhoneCallId: String = "";
  apiMobileOtpCallId: String = "";
  apiVerifyMobileOTPCallId: String = "";
  apiDeleteProfileCallId: String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.AlertMessage)
    ]

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      phoneNumber: "",
      currentCountryCode: "",
      enableField: false,
      loading: false,
      OTP: "",
      id: "",
      authToken: "",
      counter: 120,
      restartTimer: false,
      email: null,
      emailOtp: "",
      isMobileOtpValid: false,
      isEmailOtpValid: false
    };
    // Customizable Area End
    this.labelPhoneNumber = configJSON.labelPhoneNumber;
    this.labelSaveBtn = configJSON.labelSaveBtn;
    this.labelLogin = configJSON.labelLogin;
    this.labelEnterPhone = configJSON.labelEnterPhone;
    this.labelDontHaveAccount = configJSON.labelDontHaveAccount;
    this.labelSingUp = configJSON.labelSingUp;
    this.defaultCountryCode = configJSON.defaultCountryCode;
    this.labelDidntGotOtp = configJSON.labelDidntGotOtp;
    this.phoneReg = new RegExp(/^[0-9]+$/);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(
        getName(MessageEnum.AlertTitleMessage)
      );
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showNotification(AlertBodyMessage, 'error');
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetOTPCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data && responseJson.data.id) {
          this.setState({
            restartTimer: true
          });
          this.props.showNotification('OTP Sent successfully', "success")
        } else {
          this.parseApiErrorResponse(responseJson);
          this.sendAPIFailMessage();
        }

      } else if (apiRequestCallId === this.apiVerifyOTPCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.message) {
          this.changePhoneApiCall();
        } else {
          this.props.showNotification(responseJson?.errors && responseJson?.errors[0]?.pin || 'Invalid OTP', "error")
          // this.parseApiErrorResponse(responseJson);
          return
          // this.sendAPIFailMessage();
        }
      } else if (apiRequestCallId === this.apiChangePhoneCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.data && responseJson.data.id) {
          //TODO: Need to uncomment this to go with the login flow
          //runEngine.debugLog("INSIDE--apiChangePhoneCallId", JSON.stringify(responseJson));
          setTimeout(() => {
            this.redirectToAuth();
          }, 2000);
          this.props.showNotification('Phone number changed Successfully.', "success");
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
          // this.setState({isError: true, errorMessage:  this.parseApiErrorResponse(responseJson)})
          this.sendAPIFailMessage();
        }

      } else if (apiRequestCallId === this.apiMobileOtpCallId) {
        if (responseJson) {
          this.props.hideLoader();
          this.setState({
            restartTimer: true
          });
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.apiVerifyMobileOTPCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.message) {
          // this.changePhoneApiCall();
          this.setState({
            isMobileOtpValid: true,
            isEmailOtpValid: true
          })
          // setTimeout(() => {
          this.checkForAction()
          // }, 1000)
        } else {
          this.props.showNotification(responseJson?.errors && responseJson?.errors[0]?.pin || 'Invalid OTP', "error")
          // this.parseApiErrorResponse(responseJson);
          return
          // this.sendAPIFailMessage();
        }
      } else if (apiRequestCallId === this.apiDeleteProfileCallId) {
        if (responseJson && responseJson.Message) {
          this.props.hideLoader();
          this.props.showNotification(responseJson.Message, 'success')
          const keys = await StorageProvider.getKeys();
          await StorageProvider.removeMultiple(keys)
          await StorageProvider.set("user_type", this.state.user_type)
          setTimeout(() => {
            this.props.history.push("/PhoneLoginWeb");
          }, 1000);

        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }

      }
    }
    // Customizable Area End
  }

  async redirectToAuth() {
    const keys = await StorageProvider.getKeys();
    await StorageProvider.removeMultiple(keys)
    this.props.history.push('/PhoneLoginWeb')
  }
  // Customizable Area Start
  // Customizable Area End
  //STRAT --here we are validating the phone number and country code and making API call to get OTP to verify user 
  checkBothVerify() {
    this.validateMobileOtpCall()
  }

  checkForAction() {
    if (this.state.isEmailOtpValid === true && this.state.isMobileOtpValid === true) {
      if (this.props?.location?.state?.calledFrom === "deleteSpAccount") {
        this.accountDeleteApiCall()
      }
      if (this.props?.location?.state?.calledFrom === "deleteUserAccount") {
        this.accountDeleteApiCall()
      }
      if (this.props?.location?.state?.calledFrom === "updateUpiId") {
        const options = {
          pathname: '/MerchantSettings/Settings/EditPayoutDetails',
          state: { calledFrom: "otpVerificationUpi" }
        }
        this.props.history.push(options);
      }
      if (this.props?.location?.state?.calledFrom === "updateAccount") {
        const options = {
          pathname: '/MerchantSettings/Settings/EditPayoutDetails',
          state: { calledFrom: "otpVerificationAccount" }
        }
        this.props.history.push(options);
      }
    }
  }

  validatePhoneAndGetOtpCall() {

    const header = {
      "Content-Type": configJSON.contentType
    };

    this.attrs = {
      full_phone_number: this.state.phoneNumber

    }
    const data = {
      attributes: this.attrs
    };

    const httpBody = {
      token: this.state.authToken,
      data: data
    };

    this.setState({ loading: true });
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOTPCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePhoneNumberGetOTPAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  validateOtpCall() {

    let OTP: any = this.state.OTP;
    const validateOtpError = this.validateOTPNumber(OTP);
    if (validateOtpError) {
      this.setState({ otpError: validateOtpError });
      return;
    }

    this.setState({ loading: true });
    this.props.showLoader();
    const header = {
      "Content-Type": configJSON.contentType
    };

    const httpBody = {
      token: this.state.authToken,
      pin: this.state.OTP,
      full_phone_number: this.state.phoneNumber,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerifyOTPCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePhoneNumberOTPConfirmationAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  changePhoneApiCall() {

    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.contentType
    };

    const data = {
      full_phone_number: this.state.phoneNumber
    };

    const httpBody = {
      token: this.state.authToken,
      data: data,
    };
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePhoneNumberAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  //END --here we are validating the phone number and country code and making API call to get OTP to verify user 
  validatePhoneNumber(phoneNumber: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(phoneNumber)) {
      error = "Phone number " + configJSON.errorBlankField;
    } else if (!this.isValid10DigitPhoneNumber(phoneNumber)) {
      error = configJSON.errorMobileNoNotValid;
    }
    return error;

  }

  notifyMessage(msg: string) {
    Toast.show(msg)
    // Alert.alert(
    //   "Alert!",
    //   msg,
    //   [
    //     {
    //       text: "Ok",
    //       onPress: () => this.props.navigation.navigate('Auth'),
    //       style: "cancel"
    //     }
    //   ],
    //   { cancelable: false }
    // );
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  isValid10DigitPhoneNumber(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 10
    );
  }
  validateOTPNumber(otp: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(otp)) {
      error = "OTP " + configJSON.errorBlankField;
    } else if (!this.isValid4DigitPOTP(otp)) {
      error = configJSON.errorOTPNotValid;
    }
    return error;

  }
  isValid4DigitPOTP(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 4
    );
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  async sendMobileOtp() {
    this.props.showLoader();
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken

    };

    this.attrs = {
      full_phone_number: this.state.phoneNumber

    }
    const data = {
      attributes: this.attrs
    };

    const httpBody = {
      token: this.state.authToken,
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMobileOtpCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (configJSON.mobileOtpEndPoint)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async validateMobileOtpCall() {

    let OTP: any = this.state.OTP;

    const validateOtpError = this.validateOTPNumber(OTP);

    if (validateOtpError) {
      this.setState({ otpError: validateOtpError });
      return;
    }

    this.setState({ loading: true });
    this.props.showLoader();

    const header = {
      "Content-Type": configJSON.contentType
    };

    const httpBody = {
      token: this.state.authToken,
      pin: this.state.OTP,
      full_phone_number: this.state.phoneNumber,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerifyMobileOTPCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mobileOtpSettingEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async accountDeleteApiCall() {
    this.props.showLoader();
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteProfileCallId = requestMessage.messageId;

    let deleteAccountEndPoint = ('' + configJSON.deleteAccountEndPoint + '' + this.state.id)
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (deleteAccountEndPoint)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
