// @ts-nocheck
import React from "react";
import { APP_FONTS } from "../../../components/src/AppFonts";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import '../AddPayoutDetails.css'
import StorageProvider from "../../../framework/src/StorageProvider";
import AddPayoutDetailsController, {
  Props,
  configJSON,
} from "./AddPayoutDetailsController.Web";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import withRouter from "react-router/withRouter";

class EditPayoutDetails extends AddPayoutDetailsController {
  constructor(props: Props) {
    super(props);
    this.state={
      tab:-1
    }
    // Customizable Area Start
    // Customizable Area End
  }
  _saveAndContinueTapped = () => {
    this.updatePayoutDetailsAPICall();
  };
  _saveBhimUPI = () => {
    this.updateMerchantBusinsessDetailsCall();
  };
  setTab=(num)=>{
    this.setState({tab: num})
  }
  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.name == "bhimUPI" ? event.target.value.replace(" ", "") : event.target.value });
  };

  async componentDidMount() {
    let loginData = await StorageProvider.get("loginData");
    if (loginData) {
      loginData = JSON.parse(loginData);
      this.setState({
        id: loginData.id,
        authToken: loginData.token,
        emailAddress: loginData?.email
      });
    }
    let businessDetailsData = await StorageProvider.get("businessDetailsData");
    if (businessDetailsData) {
      businessDetailsData = JSON.parse(businessDetailsData);
      this.setState({
        merchantId: businessDetailsData.merchantId,
        email: businessDetailsData.email,
      });
    }
  }
  checkButtonDisable = () => {
    if(this.state.bhimUPI!=="" && this.state.bhimUPI!==undefined){
      return false
    }

    if(this.state.bankAddress !== undefined ){
      {
        if(this.state.bankAddress != undefined && this.state.bankAddress.length > 0 && this.state.bankName.length > 0 && this.state.beneficiaryName.length > 0 && this.state.accountNumber.length > 0 && (this.state.confirmAccountNumber === this.state.accountNumber) && this.state.confirmAccountNumber.length > 0 && this.state.ifscCode.length > 0 && this.state.emailAddress.length > 0 ){
          if (this.state.emailAddress)
          {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(String(this.state.emailAddress).toLowerCase());
          }    
          return false
        }else{
          return true
        }
      }
    }else{
      return true
    }

  }

  validateUPIId = () => {
    let upiReg = /^\w.+@\w+$/;
    let result =    upiReg.test(this.state.bhimUPI);
    
    return result
  }

  saveAndContinue=()=>{
    if (this.state.bhimUPI!=="" && this.state.bhimUPI!==undefined){
      if(!this.validateUPIId()){
        this.props.showNotification('Invalid UPI Id', 'error');
        return
      }
      this.updateMerchantBusinsessDetailsCall()
    }
    else if(this.state.accountNumber!==""&& this.state.accountNumber!==undefined){
      this.updatePayoutDetailsAPICall()
    }
  }
  render() {
    const { classes } = this.props;
    const {mode} = this.props;
    return (
      //Merge Engine DefaultContainer
      <>
        <Grid item xs={12} sm={6}>
          <div className={classes.tabContentLeft} style={{paddingTop:30}}>
            <Typography className={classes.screenHeading}>
              {configJSON.labelUpdatePayoutDetails}
            </Typography>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={5}>
            <div className={classes.tabContentLeft}>
              <Typography className={classes.basicInfo} variant="body1">Before We Continue</Typography>
              <Typography className={classes.basicInfo} variant="body2">Add Bank Detail(2/2)</Typography>
              <Typography variant="body2" className={classes.basicInfo} >Add UPI Details</Typography>
              <Typography className={classes.inputLabel}>
                BHIM UPI*
              </Typography>
              <InputBase
                className={classes.inputTextBox}
                placeholder={configJSON.enterBhimUpi}
                name="bhimUPI"
                value={this.state.bhimUPI}
                onChange={this.handleInputChange}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={2} >
            <div className={"orCont"}>
              <div className="orSide"/>
              <p>OR</p>
              <div className="orSide"/>
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div className={classes.tabContentLeft}>
                <Typography className={classes.basicInfo} variant="subtitle1">Add Bank Details</Typography>
                    <Typography className={classes.inputLabel}>
                      {configJSON.labelBeneficiaryName}
                    </Typography>
                    <InputBase
                      className={classes.inputTextBox}
                      // error={this.state.error ? true : false}
                      // helperText={this.state.error ? this.state.error : null}
                      placeholder={configJSON.enterBeneficiaryName}
                      value={this.state.beneficiaryName}
                      name="beneficiaryName"
                      onChange={this.handleInputChange}
                    />
                    <Typography className={classes.inputLabel}>
                      {configJSON.labelAccountNumber}
                    </Typography>
                    <InputBase
                      className={classes.inputTextBox}
                      // error={this.state.error ? true : false}
                      // helperText={this.state.error ? this.state.error : null}
                      placeholder={configJSON.enterAccountNumber}
                      value={this.state.accountNumber}
                      name="accountNumber"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={this.handleInputChange}
                    />
                    <Typography className={classes.inputLabel}>
                      {configJSON.labelConfrimAccountNumber}
                    </Typography>
                    <InputBase
                      className={classes.inputTextBox}
                      // error={this.state.error ? true : false}
                      // helperText={this.state.error ? this.state.error : null}
                      placeholder={configJSON.confirmAccountNumber}
                      value={this.state.confirmAccountNumber}
                      name="confirmAccountNumber"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={this.handleInputChange}
                    />
                    <Typography className={classes.inputLabel}>
                      {configJSON.labelBankName}
                    </Typography>
                    <InputBase
                      className={classes.inputTextBox}
                      // error={this.state.error ? true : false}
                      // helperText={this.state.error ? this.state.error : null}
                      placeholder={configJSON.enterBankName}
                      name="bankName"
                      value={this.state.bankName}
                      onChange={this.handleInputChange}
                    />
                    <Typography className={classes.inputLabel}>
                      {configJSON.labelIFSCCode}
                    </Typography>
                    <InputBase
                      className={classes.inputTextBox}
                      placeholder={configJSON.enterIFSCCode}
                      // error={this.state.error ? true : false}
                      // helperText={this.state.error ? this.state.error : null}
                      name="ifscCode"
                      value={this.state.ifscCode}
                      onChange={this.handleInputChange}
                    />
                    <Typography className={classes.inputLabel}>
                      Bank Address *
                    </Typography>
                    <InputBase
                      className={classes.inputTextBox}
                      placeholder={"Enter Bank Address"}
                      // error={this.state.error ? true : false}
                      // helperText={this.state.error ? this.state.error : null}
                      name="bankAddress"
                      value={this.state.bankAddress}
                      onChange={this.handleInputChange}
                    />
                    <Typography className={classes.inputLabel}>
                      Email Address *
                    </Typography>
                    <InputBase
                      className={classes.inputTextBox}
                      placeholder={"Enter your Email Address"}
                      // error={this.state.error ? true : false}
                      // helperText={this.state.error ? this.state.error : null}
                      name="emailAddress"
                      value={this.state.emailAddress}
                      onChange={this.handleInputChange}
                    />

            </div>
          </Grid>
        </Grid>
        <Grid container style={{width:"100%"}}>
            <div
              onClick={() => this.saveAndContinue()}
              disabled={() => this.checkButtonDisable()}
              className={classes.saveChangesBtn}
              style={{ backgroundColor:APP_COLORS.app_theme_green_color,cursor:this.checkButtonDisable()?"not-allowed":"pointer",opacity:this.checkButtonDisable()?"0.5":"1", textAlign: 'center', padding : '16px' }}
            >
              {configJSON.btnTextSaveChanges}
            </div>
        </Grid>
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    tabContentLeft: {
      width: "85%",
      margin: "auto",
    },

    //Text Styling
    screenHeading: {
      fontSize: 22,
      // fontWeight:'bold',
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
      btnContainer:{
      display:"flex",
      justifyContent:"center",
      flexDirection:"column"
    },
      basicInfo: {
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#8f92a1",
      marginTop: 27,
    },
    inputLabel: {
      fontSize: 14,
      marginTop: 15,
      letterSpacing: 1,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },

    //Input Styling
    inputTextBox: {
      padding: "5px 10px",
      width: "100%",
      marginTop: 7,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
      color: "#1e1f20",
      borderRadius: 4,
      backgroundColor: "transparent",
    },

    //Button styling
    saveChangesBtn: {
      color: "white",
      textTransform: "upper-case",
      borderRadius: 12,
      width: "85%",
      maxWidth:400,
      height: 50,
      // margin: "25px",
      padding: "4px 10px",
      marginLeft: "30px",
      marginTop: "25px",
      fontFamily: APP_FONTS.SofiaProRegular,
    },
      editBtn:{
      color: "#1e1f20",
      textTransform: "upper-case",
      borderRadius: 12,
      width: "90%",
      height: 50,
      margin:"10px auto 0",
      // marginRight: "10px",
      // marginTop: 40,
      padding: "4px 10px",
      fontFamily: APP_FONTS.SofiaProRegular,
    }
    });

      const EditPayoutDetailsWithConfirmBox = withConfirmBox(EditPayoutDetails);
      const EditPayoutDetailsWithLoader = withLoader(EditPayoutDetailsWithConfirmBox);
      const EditPayoutDetailsWithToast = withToast(EditPayoutDetailsWithLoader);
      export default withRouter(
      withStyles(styles, { withTheme: true })(EditPayoutDetailsWithToast)
      );

// Customizable Area End