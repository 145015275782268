// @ts-nocheck
import React from "react";
import { APP_FONTS } from "../../../components/src/AppFonts";
import NewPrintController, {
  Props,
  configJSON,
} from "./NewPrintController.Web";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import StorageProvider from "../../../framework/src/StorageProvider";
import DropZone from "./DropZone.Web";
import withRouter from "react-router/withRouter";
import { withToast } from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import {
  docExcel,
  docImage,
  docWord,
  docPdf,
  docPowerPoint,
  closeImage,
  textFileIcon
} from "./assets";

class NewPrint extends NewPrintController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          id: loginData.id,
          authToken: loginData.token,
        });
      }
    });
    await StorageProvider.get("selectedFiles").then((res) => {
      if (res) {
        let selectedAtt = JSON.parse(res);
        this.setState({
          uploadedFileList: selectedAtt,
        });
      }
    });
    this.getPrintingCompletedOrdersApiCall(this.state.authToken);
    await this.getAttachmentsAPICall();
  }

  handleContinue = () => {
    this.props.history.push("/UserSidebarNavigation/UploadDashBoard/SelectedDocumentsScreen");
  }

  fileSize(size: number) {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  }

  fileType(fileType: String) {
    const ImageallowedExtensions = /(jpg|jpeg|png|gif|svg)$/i;
    const pptAllowedExtension = /(ppt|pptx)$/i;
    const docAllowedExtension = /(doc|docx)$/i;
    const pdfAllowedExtension = /(pdf)$/i;
    const excelAllowedExtension = /(xlsx|xls|csv)$/i;
    if (ImageallowedExtensions.exec(fileType)) {
      return docImage;
    } else if (pptAllowedExtension.exec(fileType)) {
      return docPowerPoint;
    } else if (docAllowedExtension.exec(fileType)) {
      return docWord;
    } else if (pdfAllowedExtension.exec(fileType)) {
      return docPdf;
    } else if (excelAllowedExtension.exec(fileType)) {
      return docExcel;
    }
    return docExcel;
  }

  handleImageChange = (files) => {
    this.setState(
      { selectedFiles: [...this.state.selectedFiles, ...files] },
      () => {
        this.uploadFile();
      }
    );
  };

  BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 5,
      borderRadius: 15,
    },
    colorPrimary: {
      backgroundColor: "#CFDDD8",
    },
    bar: {
      borderRadius: 15,
      backgroundColor: "#32795f",
    },
  }))(LinearProgress);

  fileIcons = {
    'application/msword': require('../assets/docword.png'),
    'application/vnd.oasis.opendocument.text': require('../assets/docword.png'),
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': require('../assets/docword.png'),
    'image/gif': require('../assets/image_placeholder.jpg'),
    'image/png': require('../assets/image_placeholder.jpg'),
    'image/jpg': require('../assets/image_placeholder.jpg'),
    'image/jpeg': require('../assets/image_placeholder.jpg'),
    'application/pdf': require('../assets/pdf_placeholder.png'),
    'application/vnd.ms-powerpoint': require('../assets/ppt.png'),
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': require('../assets/ppt.png'),
    'application/vnd.oasis.opendocument.presentation': require('../assets/ppt.png'),
    'text/plain': textFileIcon,
    'application/vnd.ms-excel': require('../assets/docexcel.png'),
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': require('../assets/docexcel.png'),
    'application/vnd.oasis.opendocument.spreadsheet': require('../assets/docexcel.png'),
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.rightContent}>
        <Grid item xs={12}>
          <Typography className={classes.selectFilesText}>
            Select Files To Print
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          className={classes.docsContainer}
        >
          {this.state.selectedFiles.map((file) => (
            <Grid
              item
              key={file.name}
              xs={12}
              sm={6}
              md={3}
              className={classes.eachDocsParent}
            >
              {
                file.type === ('application/msword' || file.type === 'application/vnd.oasis.opendocument.text' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'application/pdf'
                  || file.type === 'text/plain' || file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') || (file.type === 'application/vnd.oasis.opendocument.presentation'
                    || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.type === 'application/vnd.oasis.opendocument.spreadsheet'
                  ) ?
                  <div className={classes.eachDocs}>
                    <img src={this.fileIcons[file.type] || require('../assets/pdf_placeholder.png')} className={classes.docImg} />
                    <Typography className={classes.docTitle}>
                      {this.state.uploading ? "Uploadings..." : file.name}
                    </Typography>


                    <Typography className={classes.docContent}>
                      {this.state.loading} &nbsp; %
                    </Typography>
                    <div className={classes.progressCont}>
                      <this.BorderLinearProgress
                        variant="determinate"
                        value={this.state.loading}
                        className={classes.progressBar}
                      />
                      <img
                        src={closeImage}
                        alt=""
                        className={classes.progressCancel}
                      />
                    </div>
                  </div>
                  : null
              }
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} style={{ cursor: "pointer" }}>
          <DropZone
            onSelectFile={this.handleImageChange.bind(this)}
            multiple={true}
          />
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    rightContent: {
      height: "100%",
      width: "100%",
      borderRadius: 12,
      marginBottom: 40,
      display: "inline-block",
      paddingBottom: "40px",
      backgroundSize: "cover",
    },
    selectFilesText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: 16,
        left: "20px",
        top: "10px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: 22,
        left: "75px",
        top: "20px",
      },
      "@media (min-width: 1280px)": {
        fontSize: 22,
        left: "75px",
        top: "20px",
      },
      margin: "20px 0 0px 20px",
      fontWeight: "normal",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
      position: "relative",
      width: "80%"
    },
    contentLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    docsContainer: {
      margin: "12px auto",
    },
    progressBar: {
      width: "90%",
      margin: "10px auto",
    },
    eachDocs: {
      border: "solid 2px rgba(143, 146, 161, 0.2)",
      borderRadius: 12,
      padding: "20px 10px",
      display: "flex",
      background: "#f7f7f7",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    eachDocsParent: {
      background: "#7f7f7",
    },
    docNav: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    docNavImg: {
      height: 16,
      width: 16,
      marginRight: 5,
      objectFit: "contain",
    },


    descCont: {
      display: "flex",
      borderRadius: 12,
      backgroundColor: "white",
      padding: "10px 20px",
      width: "90%",
      margin: "auto",
    },
    eachDesc: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "auto",
      marginTop: "20px",
    },
    uploadCont: {
      margin: "auto",
      marginTop: "20px",
      border: "2px dotted #77c285",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 12,
      padding: "20px 10px",
    },
    uploadImg: {
      height: 130,
      width: 130,
      margin: "auto",
      objectFit: "contain",
    },
    uploadText: {
      fontSize: 22,
      width: "80%",
      margin: "20px auto",
      fontWeight: "normal",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
    },
    docTitle: {
      fontWeight: "bold",
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
      margin: "10px 0",
      wordBreak: 'break-all'
    },
    docImg: {
      height: 50,
      width: 65,
      margin: "10px auto",
      objectFit: "contain",
      mixBlendMode: "luminosity",
    },
    docContent: {
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#8f92a1",
    },
    progressCont: {
      width: "calc(100% - 14px)",
      display: "flex",
      alignItems: "center",
      height: 30,
      justifyContent: "space-between",
    },
    progressCancel: {
      width: 18,
      height: 18,
      objectFit: "contain",
      marginLeft: 10,
      cursor: "pointer",
    },
    btnCont: {
      margin: "auto",
      marginTop: "20px",
      borderRadius: 12,

    },
    findPrintersBtn: {
      backgroundColor: "#32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: "20px auto 0 0",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
      fontSize: "20px",
    },
  });
const Loader = withLoader(NewPrint)
const toast = withToast(Loader)
export default withRouter(withStyles(styles, { withTheme: true })(toast));