// @ts-nocheck
import React from "react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
// Customizable Area End
import OTPInputView from '../../../components/src/OtpInput.web';
import StorageProvider from '../../../framework/src/StorageProvider';
import withRouter from "react-router/withRouter";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";

import ChangePhoneOTPVerifyController, {
  Props
} from "./ChangePhoneOTPVerifyController.Web";

class ChangePhoneOTPVerify extends ChangePhoneOTPVerifyController {
  timer = null;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  getOtp(OTP: string) {
    this.setState({ OTP });
  }

  async componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  async componentDidMount() {

    //  Customizable Area Start
    this.timer = setInterval(this.manageTimer, 1000);
    await StorageProvider.get('loginData').then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          id: loginData.id,
          authToken: loginData.token
        });
      }
    });

    if ((this.props?.location?.state?.calledFrom === "deleteUserAccount" || this.props?.location?.state?.calledFrom === "deleteSpAccount" || this.props?.location?.state?.calledFrom === "updateUpiId" || this.props?.location?.state?.calledFrom === "updateAccount")) {
      this.setState({
        phoneNumber: this.props?.location?.state?.phone,
        email: this.props?.location?.state?.email
      })
    } else {
      await StorageProvider.get('cphoneData').then((res) => {
        const cphoneData = JSON.parse(res);
        if (cphoneData) {
          this.setState({
            phoneNumber: cphoneData.phone
          }, () => {
            // this.validatePhoneAndGetOtpCall();
          });
        }
      });
    }


  }


  manageTimer = () => {
    if (this.state.counter > 0) {
      this.setState(({ counter }) => ({
        counter: counter - 1
      }))
    }
    if (this.state.counter === 0) {
      clearInterval(this.timer)
    }
  }
  formatNumber = (number) => {
    return `+${number.substring(0, 2)} ${number.substring(2, number.length)}`
  }
  render() {
    const { classes } = this.props;
    if (this.state.restartTimer) {
      this.setState({
        counter: 120
      });
      this.timer = setInterval(this.manageTimer, 1000);
      this.setState({
        restartTimer: false
      });
    }
    return (
      <Grid item xs={12} className={classes.rightBackground}>
        <Grid item xs={12} className={classes.parentContainer}>
          <Typography className={classes.checkYourSMSBoxTxt}>
            {
              this.props?.location?.state?.calledFrom === undefined ?
                `We have sent an OTP on your phone number ${this.formatNumber(this.state.phoneNumber)}`
                :
                `We have sent an OTP on your phone number ${this.formatNumber(this.state.phoneNumber)} and your email ${this.state.email}`

            }
          </Typography>
          <Typography className={classes.otpTxt}>
            OTP
          </Typography>

          <OTPInputView OTPLength={4} value={this.state.OTP} className="change-passcode" otpType={"number"}
            onOTPChange={(otp) =>
              this.getOtp(otp)
            }
            secure={false} />
          <Typography className={classes.didntGetTheOTP}>
            {this.labelDidntGotOtp}
            {
              this.state.counter == 0 ? "" : (
                <span style={{ marginLeft: 7, textDecoration: "underline" }}>{`Resend In 0${Math.floor(this.state.counter / 60)}:${(this.state.counter % 60).toString().length > 1 ? this.state.counter % 60 : "0" + this.state.counter % 60}`}</span>
              )
            }
            {
              this.state.counter == 0 ? (
                <span style={{ marginLeft: 7, textDecoration: "underline" }} onClick={() => {
                  if ((this.props?.location?.state?.calledFrom === "deleteUserAccount" || this.props?.location?.state?.calledFrom === "deleteSpAccount" || this.props?.location?.state?.calledFrom === "updateUpiId" || this.props?.location?.state?.calledFrom === "updateAccount")) {
                    this.sendMobileOtp()
                  } else {
                    this.validatePhoneAndGetOtpCall()
                  }
                }}>RESEND OTP</span>
              ) : ""
            }
          </Typography>
          <Grid>
            <Typography className={classes.errorLabel}>
              {this.state.otpError}
            </Typography>
          </Grid>

          <Button
            className={classes.saveButton}
            onClick={() => {
              if ((this.props?.location?.state?.calledFrom === "deleteUserAccount" || this.props?.location?.state?.calledFrom === "deleteSpAccount" || this.props?.location?.state?.calledFrom === "updateUpiId" || this.props?.location?.state?.calledFrom === "updateAccount")) {
                this.checkBothVerify()
              } else {
                this.validateOtpCall()
              }

            }}
          >
            <Typography className={classes.saveButtonTxt}>{
              (this.props?.location?.state?.calledFrom === "deleteUserAccount" || this.props?.location?.state?.calledFrom === "deleteSpAccount" || this.props?.location?.state?.calledFrom === "updateUpiId" || this.props?.location?.state?.calledFrom === "updateAccount") ?
                "Verify And Proceed"
                :
                "Save"
            }</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  rightBackground: {
    borderRadius: "32px",
    backgroundColor: "#fff"
  },
  parentContainer: {
    padding: "30px"
  },
  checkYourSMSBoxTxt: {
    fontFamily: "SofiaPro",
    fontSize: "16px",
    fonWeight: "normal",
    width: "50%",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    color: "#2f2f30"
  },
  otpTxt: {
    margin: "35px 30px 15px 0",
    fontFamily: "SofiaPro",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "1px",
    color: "#1e1f20"
  },
  didntGetTheOTP: {
    fontFamily: 'SofiaPro',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    marginTop: 25,
    letterSpacing: '-0.35px',
    color: '#565656',
    cursor: "pointer"
  },
  saveButton: {
    margin: "56px 2px 0 0",
    padding: "18px 143px 20px",
    borderRadius: "12px",
    backgroundColor: "#32795f",
    "&:hover": {
      backgroundColor: "#32795f"
    }
  },
  saveButtonTxt: {
    fontFamily: "SofiaPro",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff"
  },
  errorLabel: {
    color: "red",
    marginTop: 15,
    fontFamily: "SofiaPro"
  }
});

const ChangePhoneOTPVerifyWithLoader = withLoader(ChangePhoneOTPVerify)
const ChangePhoneOTPVerifyWithToast = withToast(ChangePhoneOTPVerifyWithLoader)
export default withRouter(withStyles(styles, { withTheme: true })(ChangePhoneOTPVerifyWithToast));