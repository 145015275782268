// @ts-nocheck
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from "../../../framework/src/StorageProvider";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import ChangePhoneNumberController, {
  Props,
  configJSON,
} from "./ChangePhoneNumberController.Web";
import withRouter from "react-router/withRouter";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";

class ChangePhoneNumberBlock extends ChangePhoneNumberController {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentCountryCode: "91",
    };
  }

  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          id: loginData.id,
          authToken: loginData.token,
        });
      }
    });
  }
  navigateToVerifyOTpScreen = async () => {
    let countryCode: any = this.state.currentCountryCode;
        countryCode = countryCode.indexOf("+") > 0
          ? countryCode.split("+")[1]
          : countryCode

        let data: any = {}
        data.phone = countryCode + this.state.phoneNumber;
        await StorageProvider.remove('cphoneData')
        await StorageProvider.set('cphoneData', JSON.stringify(data));

        this.props.history.push("ChangePhoneOTPVerify");
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container item direction="column" className={classes.container}>
        <Grid container justify="flex-start" item xs={12} >
          <Typography className={classes.updateTxt}>
            {configJSON.labelUpdate}
          </Typography>
        </Grid>
        <Grid container justify="flex-start" item xs={12} >  
          <Typography className={classes.changePhoneNumberTxt}>
            {configJSON.labelChangePhoneNumber}
          </Typography>
        </Grid>
        <Grid container justify="flex-start" item xs={12}>  
          <Grid item xs={12}>
          <Typography className={classes.phoneNoLabel}>PHONE NUMBER</Typography>
          </Grid>
          <Grid item xs={12} className={classes.countryCont}>
            <div className={classes.countryCont}>
              <CountryCodeSelector
                allowPropChange={true}
                isAllFlagAvailable={true}
                disable={true}
                id={"CountryCodeSelector"}
                placeHolder={this.state.currentCountryCode}
                disable={false}
                value={this.state.currentCountryCode}
              />
              <InputBase
                error={this.state.PhoneNumberError ? true : false}
                helperText={this.state.PhoneNumberError ? this.state.PhoneNumberError : ""}
                type="number"
                fullWidth
                className={`text-field`}
                style={{height:45}}
                maxLength={10}
                onInput = {(e) =>{
                  e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                }}
                value={this.state.phoneNumber}
                onChange={(e) => {
                  this.onChangePhoneNumber(e);
                }}
              />
            </div>
           </Grid>
         </Grid> 
         <p className={`error-label ${classes.sofiaFont}`}>
            {this.state.PhoneNumberError}
          </p>
          <Grid item xs={6}>
            <Button
              className={classes.continueButton}
              onClick={() => this.validatePhoneAndGetOtpCall()}
            >
              <Typography className={classes.continueButtonTxt}>
                Continue
              </Typography>
            </Button>
          </Grid>
        </Grid>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container : {
      padding : '7%'
    },
    countryCont:{
      width:"100%",
      display:"flex",
      marginTop:15,
      maxWidth:350
    },
    sofiaFont:{
      fontFamily:APP_FONTS.SofiaProRegular
    },
    updateTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.36,
      letterSpacing: "normal",
      color: "#1e1f20",
    },
    changePhoneNumberTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "#8f92a1",
      marginTop: "50px",
    },
    phoneNoLabel: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.67,
      letterSpacing: "1px",
      color: "#1e1f20",
      marginTop: "30px",
    },
    continueButton: {
      width: "319px",
      height: "58px",
      margin: "72px 0 0",
      padding: "18px 124px 20px 125px",
      borderRadius: "12px",
      backgroundColor: "#32795f",
      "&:hover": {
        backgroundColor: "#32795f",
      },
    },
    continueButtonTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
    },
    errorLabel: {
      color: "red",
      fontFamily: APP_FONTS.SofiaProRegular,
    },
  });
  const ChangePhoneNumberBlockWithLoader = withLoader(ChangePhoneNumberBlock)
  const ChangePhoneNumberBlockWithToast = withToast(ChangePhoneNumberBlockWithLoader)
  export default withRouter(withStyles(styles, { withTheme: true })(ChangePhoneNumberBlockWithToast));
  
