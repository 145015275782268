// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';
// Customizable Area Start
// Customizable Area End

import { RouterProps  } from "react-router";
export const configJSON = require("./config");

export type Props = RouterProps & {
    id: string;
    classes: any;
    showNotification: any;
    showLoader: any;
    hideLoader: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
  // Customizable Area Start
  phoneNumber: any;
  currentCountryCode: any;
  loading: boolean;
  phoneNumberError: string;
  errorMessage: string;
  alertMessage:string,
  alertOpen:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ForgotPasscodeControllerweb extends BlockComponent<
  Props,
  S,
  SS
  > {

  labelPhoneNumber: string;
  labelNextBtn: string;
  labelForgotPasscode: string;
  labelForgotPasscodeMsg: string;
  labelForgotPasscodeMessage: string;
  defaultCountryCode: String;
  phoneReg: RegExp;
  apiType: string;
  attrs: {};
  apiGetOTPLCallId: String = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.AlertMessage),
    ]

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      phoneNumber: "",
      currentCountryCode: "91",
      enableField: false,
      loading: false,
      phoneNumberError: "",
      errorMessage: "",
      alertMessage:"",
      alertOpen:false,
    };
    // Customizable Area End
    this.labelPhoneNumber = configJSON.labelPhoneNumber;
    this.labelNextBtn = configJSON.labelNextBtn;
    this.labelForgotPasscodeMessage = configJSON.labelForgotPasscodeMessage;
    this.labelForgotPasscode = configJSON.labelForgotPasscode;
    this.labelForgotPasscodeMsg = configJSON.labelForgotPasscodeMsg;
    this.defaultCountryCode = configJSON.defaultCountryCode;
    this.phoneReg = new RegExp(/^[0-9]+$/);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
    //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    //   this.showAlert(
    //     "Change Value",
    //     "From: " + this.state.txtSavedValue + " To: " + value
    //   );

    //   this.setState({ txtSavedValue: value });

    // }
    if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showNotification(AlertBodyMessage, "error");
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
      runEngine.debugLog("selectedCode", selectedCode);
      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode: selectedCode?.value
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetOTPLCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          let countryCode: any = this.state.currentCountryCode;
          countryCode = countryCode.indexOf("+") > 0
            ? countryCode.split("+")[1]
            : countryCode

          let data: any = {}
          data.phone = this.state.phoneNumber;
          data.countryCode = countryCode;
          data.token = responseJson.meta.token;
          await StorageProvider.remove('fpData');
          await StorageProvider.set('fpData', JSON.stringify(data));
          setTimeout(() => {
            this.props.history.push({
               pathname: '/ForgotPasswordVerifyOtp',
               state: { user_type: this.state.value }
            });  
          }, 1000);
          
          this.props.showNotification("OTP sent Successfully","success");
        } else {
          this.parseApiErrorResponse(responseJson);
          this.sendAPIFailMessage();
        }
      }
    }
    // Customizable Area End
  }

  

  // Customizable Area Start

  onChangePhoneNumber = (event:any) => {
    this.setState({phoneNumber: event.target.value});
  }

  // Customizable Area End
  //STRAT --here we are validating the phone number and country code and making API call to get OTP to verify user 
  async validatePhoneAndGetOtpCall() {

    let mobileNo: any = this.state.phoneNumber;
    const validatePhoneError = this.validatePhoneNumber(mobileNo);

    if (validatePhoneError) {
      this.setState({phoneNumberError: validatePhoneError});
      return;
    }
    //here we need to call GET OTP API CALL
    let countryCode: any = this.state.currentCountryCode;
    countryCode = countryCode.indexOf("+") > 0
      ? countryCode.split("+")[1]
      : countryCode

    this.attrs = {
      full_phone_number: countryCode + mobileNo,
      user_type: this.state.value 
    }

    const header = {
      "Content-Type": configJSON.apiContentType
    };

    const data = {
      attributes: this.attrs
    };

    const httpBody = {
      data: data
    };
    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOTPLCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasscodeGetOTPAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  validatePhoneNumber(phoneNumber: string) {
    let error = null;
    if (!this.isNonNullAndEmpty(phoneNumber)) {
      error = "Phone number " + configJSON.errorBlankField;
    } else if (!this.isValid10DigitPhoneNumber(phoneNumber)) {
      error = configJSON.errorMobileNoNotValid;
    }
    return error;

  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  isValid10DigitPhoneNumber(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length == 10
    );
  }

  sendAPIFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
}
