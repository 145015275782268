// @ts-nocheck
import React from "react";
import {PureComponent} from "react";
import RouterProps from "react-router/RouterProps";
import withRouter from "react-router/withRouter";
import Redirect from "react-router/Redirect";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../framework/src/StorageProvider";
export type Props = RouterProps & {
    openDialog : any;
}

class Logout extends PureComponent<Props> {
    state = {
        loaded : false,
        logout : null
    }
    logoutConfirmationDialog = () =>{
        const {openDialog} = this.props;
        const onClose = (msg:any)=>{
            if(msg === 'Yes'){
              this.clearStorageAndredirectToLogin()
            }
        }
        this.props.openDialog('Logout Confirmation','Are you sure want to logout from the Application?',onClose);
      }
        
    
      clearStorageAndredirectToLogin = async () => {
        await StorageProvider.remove("loginData");
        await StorageProvider.remove("signUpData");
        await StorageProvider.remove("selectedFiles");
        await StorageProvider.remove("orderId");
        this.setState({logout:true})
      };

    componentDidMount(){
        this.setState({loaded:true},()=>{
            this.logoutConfirmationDialog();
        })
        
    }
    render(){
        const d = (this.state.loaded && this.state.logout) ? <Redirect to="/"></Redirect> : null;
        return d;
    }
}
const a = withConfirmBox(Logout);
export default withRouter(withConfirmBox(Logout));
  
  