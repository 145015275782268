//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';
// import RazorpayCheckout from 'react-native-razorpay';
import { printIcon } from "./assets";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  id: string;
  authToken: string;
  filePath: any,
  fileName: String;
  fileType: any;
  imageUri: any;
  attachmentIds: Array<string>;
  orderAmount: number;
  attachmentDetails: any[];
  taxAmount: number;
  totalAmount: number;
  orderId: number;
  razorpay_payment_id: string;
  payment_via: string;
  payWithUpi: boolean;
  upiId: any;
  orderIsCreated: boolean;
  razorpay_order_id: string;
  razorpay_signature: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderPaymentDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {

  labelPhoneNumber: string;
  labelNextBtn: string;
  labelLogin: string;
  labelEnterPhone: string;
  labelDontHaveAccount: string;
  defaultCountryCode: String;
  labelSingUp: string;
  phoneReg: RegExp;
  apiType: string;
  attrs: {};
  emailReg: RegExp;

  apiPlaceOrderCallId: String = "";
  apiCreateOrderCallId: String = "";
  apiGetOrderAmountDetail: String = "";
  apiGetOrderAmountDetailsCallId: String = "";
  apiUpdateOrderStatusCallId: String = "";
  apiCreateTransactionCallId: String = "";
  apiPayWithUpiStatusCallId: string = "";
  handleTimeInterval: null;
  apiCheckUpiStatusCallId: string = "";
  apiVerifySecurityKeysCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.updateOrderStatus = this.updateOrderStatus.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      loading: false,
      id: "",
      authToken: "",
      filePath: {},
      fileName: "",
      fileType: "",
      imageUri: '',
      attachmentIds: [],
      orderAmount: 0.00,
      attachmentDetails: [],
      taxAmount: 0.00,
      totalAmount: 0.00,
      orderId: 0,
      razorpay_payment_id: "",
      payment_via: "",
      payWithUpi: false,
      upiId: "",
      orderIsCreated: false,
      razorpay_order_id: "",
      razorpay_signature: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiPlaceOrderCallId) {
        await this.setState({ loading: false })
        if (responseJson && responseJson.data && responseJson.data.id) {
          //TODO: Need to uncomment this to go with the login flow
          //Here clear all stored values in storage redirect to QR code screen

          let data: any = {}
          data.orderId = responseJson.data.id;
          data.goBack = false;
          await StorageProvider.remove('orderId');
          await StorageProvider.set('orderId', JSON.stringify(data));

          //  this.showAlert('Success!!!', responseJson.meta.message);
          // this.props.navigation.navigate('PaymentSuccessfullScreen');
          await this.setState({ orderId: responseJson.data.id });

          // calling transaction api to get razor order-id

          this.createTransactionApiCall();

          // this.payWithUpiMethod()
          // setTimeout(() => {
          // this.openRazorPay(responseJson.data.id);
          // }, 100);


        } else if (responseJson && responseJson.message) {
          //TODO: Need to uncomment this to go with the login flow
          this.showAlert("Response Alert!", responseJson.message);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.apiGetOrderAmountDetailsCallId) {
        await this.setState({ loading: false })
        if (responseJson && responseJson.data) {
          //TODO: Need to uncomment this to go with the login flow
          //Here clear all stored values in storage redirect to QR code screen
          // if (responseJson.data.attributes) {
          //   this.setState({
          //     orderAmount: responseJson.data.attributes.order_amount,
          //     taxAmount: responseJson.data.attributes.tax_amount,
          //     totalAmount: responseJson.data.attributes.total_amount,
          //   })
          // }
        } else if (responseJson && responseJson.message) {
          //TODO: Need to uncomment this to go with the login flow
          this.showAlert("Response Alert!", responseJson.message);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
        this.props.hideLoader()
      } else if (apiRequestCallId === this.apiUpdateOrderStatusCallId) {
        if (responseJson && responseJson.data) {
          //TODO: Need to uncomment this to go with the login flow
          // setTimeout(() => {
          //     this.showAlert("Response Alert!", "Order status has been updated with Payment Completed");
          // }, 100);

          setTimeout(() => {
            // this.createTransactionApiCall();
          }, 1000);

          // this.props.navigation.goBack();
        } else if (responseJson && responseJson.message) {
          await this.setState({ loading: false });
          setTimeout(() => {
            this.showAlert("Response Alert!", responseJson.message);
          }, 100);
        }
        else {
          //Check Error Response
          await this.setState({ loading: false });
          setTimeout(() => {
            this.parseApiErrorResponse(responseJson);
          }, 100);
        }
      } else if (apiRequestCallId === this.apiCreateTransactionCallId) {
        if (responseJson && responseJson.data) {
          this.setState({
            razorpay_order_id: responseJson?.data?.attributes?.razorpay_order_id,
          }, () => {
            this.openRazorPay()
          })

        } else if (responseJson && responseJson.message) {
          this.props.hideLoaderPayment();
          setTimeout(() => {
            this.props.showNotification(responseJson.message, "error");
          }, 0);
        }
        else {
          //Check Error Response
          setTimeout(() => {
            this.parseApiErrorResponse(responseJson);
          }, 100);
        }
        this.props.hideLoader();
      }

      else if (apiRequestCallId === this.apiPayWithUpiStatusCallId) {

        if (responseJson && responseJson.data) {
          this.handleTimeInterval = setInterval(() => this.checkUpiStatus(), 10000);
          await StorageProvider.remove('transactionId');
          await StorageProvider.set('transactionId', responseJson.data?.id);
          this.setState({ payWithUpi: false });
        } else if (responseJson && responseJson.message) {
          setTimeout(() => {
            this.showAlert("Response Alert!", responseJson.message);
          }, 100);
          this.props.showNotification(responseJson.message, "error");
          this.props.hideLoaderPayment();
        }
        else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }

      else if (apiRequestCallId === this.apiCheckUpiStatusCallId) {
        await this.setState({ loading: true });
        this.setState({ payWithUpi: false });
        if (responseJson && responseJson.data) {

          if (responseJson.data.transaction_status === "SUCCESS") {
            this.props.history.push('/UserSidebarNavigation/UploadDashBoard/PaymentSuccessfullScreen')
            clearInterval(this.handleTimeInterval);
            this.props.hideLoaderPayment();
            this.props.showNotification(responseJson.message, "success");
          }

          if (responseJson.data.transaction_status === "FAILURE") {
            clearInterval(this.handleTimeInterval);
            this.props.hideLoaderPayment();
            this.setState({ payWithUpi: false });
            this.props.showNotification(responseJson && responseJson.data.message, "error");
          }
        } else if (responseJson && responseJson.data && responseJson.data.message) {
          setTimeout(() => {
            this.showAlert("Response Alert!", responseJson.data.message);
          }, 100);
        }
        else {
          //Check Error Response
          setTimeout(() => {
            this.parseApiErrorResponse(responseJson);
          }, 100);
          clearInterval(this.handleTimeInterval)
        }
      }
      else if (apiRequestCallId == this.apiCreateOrderCallId) {
        let data: any = {}
        data.orderId = responseJson.data.id;
        data.goBack = false;
        await StorageProvider.remove('orderId');
        await StorageProvider.set('orderId', JSON.stringify(data));
        await StorageProvider.remove('orderIdDetails');
        await StorageProvider.set('orderIdDetails', JSON.stringify(responseJson.data));
        this.getAttachmentAmount()

        if (responseJson.data) {
          await this.setState({ orderId: responseJson.data.id });
          if (this.state.payment_via === 'Razorpay') {
            this.createTransactionApiCall();
          } else {
            this.payWithUpiMethod()
          }
          // this.openRazorPay(responseJson.data.id);
        } else {
          self.props.showNotification(`${response?.error?.description}`, "error")
        }
      }
      else if (apiRequestCallId == this.apiGetOrderAmountDetail) {
        if (responseJson && responseJson.data) {
          let truelength = 0;
          for (const property in responseJson.meta) {
            if (responseJson?.meta[property] === true) {
              truelength++
            }
          }
          if (responseJson?.meta) {
            this.setState({
              orderIsCreated: Object?.keys(responseJson?.meta)?.length === truelength
            })
          }

          this.setState({
            attachmentDetails: responseJson.data.attributes.order_attachments.attachment_details,
            orderAmount: responseJson.data.attributes.order_amount,
            taxAmount: responseJson.data.attributes.tax_amount,
            totalAmount: responseJson.data.attributes.total_amount,
          })
        }
      }
      else if (apiRequestCallId == this.apiVerifySecurityKeysCallId) {
        if (responseJson?.data) {
          this.props.showNotification(responseJson?.meta?.message, "success")
          setTimeout(() => {
            this.props.history.push(
              "/OrderManagement/OrderHistory/OrderDetail"
            );
          }, 1000);
        } else {
          this.props.showNotification(responseJson?.message || responseJson?.error, "error")
        }
        await this.setState({ loading: false })
        this.props.hideLoader()
        this.props.hideLoaderPayment();
      }
    }
    // Customizable Area End
  }

  componentWillUnmount() {
    clearInterval(this.handleTimeInterval)
  }

  // Customizable Area End
  async placeOrderApiCall() {
    if (this.state.orderId == 0) {
      const header = {
        "Content-Type": configJSON.contentType,
        uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

      };

      const data = {
        account_id: Number(this.state.id),
        status: 'pending',
        total_docs: Number((this.state.attachmentIds).length),
        attachment_ids: this.state.attachmentIds
      };
      const httpBody = {
        data: data
      };

      this.setState({ loading: true });

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiPlaceOrderCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.placeOrderAPiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPiEndMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.openRazorPay(this.state.orderId);
    }
  }


  async createOrder() {
    if (!this.validateUPIId() && this.state.payment_via !== "Razorpay") {
      this.props.showNotification('Invalid UPI Id', 'error');
      return
    }

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

    };

    let selectedFiles = await StorageProvider.get("selectedFiles")
    const selectedAttachment = selectedFiles && JSON.parse(selectedFiles);
    let attachmentIds = []
    if (selectedAttachment) {
      attachmentIds = selectedAttachment.map(
        (attachment) => attachment.id
      );
    }


    const data = {
      account_id: Number(this.state.id),
      status: 'pending',
      total_docs: Number((attachmentIds).length),
      attachment_ids: attachmentIds
    };
    const httpBody = {
      data: data
    };

    this.setState({ loading: true, payWithUpi: false });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateOrderCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.placeOrderAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async createTransactionApiCall() {
    const paymentVia = this.state.payment_via;

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

    };

    const data = {
      order_id: this.state.orderId,
      payment_via: paymentVia,
    };
    const httpBody = {
      data: data
    };

    //  this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateTransactionCallId = requestMessage.messageId;

    let createTransactionAPIEndPoint = ('' + configJSON.createTransactionAPIEndPoint).split('orderId').join('' + this.state.orderId);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      createTransactionAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async verifySignatureApiCall() {
    // const paymentVia = this.state.payment_via;

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

    };

    const data = {
      transaction_type: "Paid",
      order_id: parseInt(this.state.orderId),
      razorpay_order_id: this.state.razorpay_order_id || "",
      razorpay_payment_id: this.state.razorpay_payment_id || "",
      razorpay_signature: this.state.razorpay_signature || ""
    };

    const httpBody = {
      data: data
    };

    //  this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerifySecurityKeysCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_ordermanagement/orders/verify_signature"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async getOrderAmountDetailsApiCall() {
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

    }
    const data = {
      "order_id": Number(this.state.orderId)
    }
    const httpBody = {
      data: data
    }
    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOrderAmountDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateQrCodesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async getAttachmentAmount() {
    let selectedFiles = await StorageProvider.get("selectedFiles")
    const selectedAttachment = selectedFiles && JSON.parse(selectedFiles);
    let attachmentIds = []
    if (selectedAttachment) {
      attachmentIds = selectedAttachment.map(
        (attachment) => attachment.id
      );
    }

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

    }
    const data = {
      "attachment_ids": attachmentIds,
      "total_docs": attachmentIds.length
    }
    const httpBody = {
      data: data
    }
    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOrderAmountDetail = requestMessage.messageId;
    let order = await StorageProvider.get("orderId")
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_ordermanagement/orders/get_order_amount` + "?data[order_id]=" +
      JSON.parse(order).orderId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  openRazorPay = async () => {
    const self = this;
    let email = "";
    let name = "";
    let contact = "";
    await StorageProvider.get('loginData').then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        email = loginData.email,
          name = loginData.name,
          contact = loginData.phone;
      }
    });

    const orderAmount = parseFloat(this.state.totalAmount).toFixed(2) * 100;
    var options = {
      description: 'PrintSec Secure Printing',
      image: printIcon,
      currency: 'INR',
      key: window.location.hostname === "www.printsec.app" ? 'rzp_live_zAJUdwu6bC5JXG' : 'rzp_test_MnPssPE2QhSVO2',
      order_id: this.state.razorpay_order_id,
      amount: `${Math.round(orderAmount)}`,
      handler: function (response) {
        self.setState({
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response?.razorpay_order_id,
          razorpay_signature: response?.razorpay_signature
        }, () => {
          self.verifySignatureApiCall()
        });

        self.props.showLoader();
      },
      name: 'Printsec pvt ltd',
      prefill: {
        email: email,
        contact: contact,
        name: name
      },
    }

    var rzp1 = new Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      self.props.showNotification(`${response.error.description}`, "error")
    });
    rzp1.open();
  }

  updateOrderStatus = async () => {
    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateOrderStatusCallId = requestMessage.messageId;

    let updateOrderStatusAPIEndPoint = ('' + configJSON.updateOrderStatusAPIEndPoint).split('orderId').join('' + this.state.orderId)

    updateOrderStatusAPIEndPoint = updateOrderStatusAPIEndPoint + "?data[status]=Paymentcompleted";

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), updateOrderStatusAPIEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  validateUPIId = () => {
    let upiReg = /^\w.+@\w+$/;
    let result = upiReg.test(this.state.upiId);
    return result
  }


  payWithUpiMethod = async () => {
    if (!this.validateUPIId()) {
      this.props.showNotification('Invalid UPI Id', 'error');
      return
    }

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""
    };

    const data = {
      "order_id": Number(this.state.orderId),
      "payerva": this.state.upiId,
      "payment_via": "Upi"
    }

    const httpBody = {
      data: data
    }

    this.setState({ loading: true, payWithUpi: false });
    this.props.showLoaderPayment();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPayWithUpiStatusCallId = requestMessage.messageId;

    let payWithUpiAPIEndPoint = configJSON.placeUpiOrder

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), payWithUpiAPIEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  checkUpiStatus = async () => {

    const header = {
      "Content-Type": configJSON.contentType,
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const getTransactionId = await StorageProvider.get('transactionId');

    this.apiCheckUpiStatusCallId = requestMessage.messageId;

    let checkUpiStatusAPIEndPoint = configJSON.getUpiOrderStatus + "/" + this.state.orderId + "/get_upi_transaction_status?" + `transaction_id=${getTransactionId}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), checkUpiStatusAPIEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  displayAlert = () => {
    this.props.showNotification("Order created Successfully and order status has been updated with Payment Completed", "success")
    setTimeout(() => {
      this.props.history.push('/UserSidebarNavigation/UploadDashBoard/PaymentSuccessfullScreen')
    }, 1000)
  }

  dismissSpinner = () => {
    this.setState({ loading: false });
    this.props.navigation.navigate('PaymentSuccessfullScreen')
  }
}
