//*> SelectDocuments Screeen

export const settingIcon = require('../assets/settingIcon.png');
export const deleteIcon = require('../assets/deleteIcon.png');

export const docWordIcon = require('../assets/docword.png');
export const exceWordIcon = require('../assets/docexcel.png');
export const printerDocIcon = require('../assets/printerDocIcon.png');
export const shopQrImg = require('../assets/shopQrDash.png');

export const navBarImg = require('../assets/user.png');
export const attachIcon = require('../assets/attach.svg');
export const checkIcon = require('../assets/check.svg');
export const arrowRight = require('../assets/arrow-right.svg');

export const homeSelected = require('../assets/home_selected.svg');
export const home_unselected = require('../assets/home_unselected.svg');

