// @ts-nocheck
import React from "react";
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import StorageProvider from "../../../framework/src/StorageProvider";
import { cameraIcon, profileIcon, deleteIcon, mapsIcons, closeIcon } from "./assets";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import withRouter from "react-router/withRouter";
import EditUserProfileController, {
  Props,
  configJSON,
} from "./EditUserProfileController.Web";
import { APP_FONTS } from "../../../components/src/AppFonts";
import OTPInputView from '../../../components/src/OtpInput.web';


class EditUserProfile extends EditUserProfileController {
  constructor(props: Props) {
    super(props);
  }

  onUsernameChanged = (event: any) => {
    this.setState({ username: event.target.value });
  };

  onAddressChanged = (event: any) => {
    this.setState({ address: event.target.value });
  };

  onEmailChanged = (event: any) => {
    this.setState({ email: event.target.value });
  };


  // this is to get current geolocation and address from geolocation
  requestLocationPermission = async () => {
    this.getOneTimeLocation();
  };


  getOneTimeLocation = () => {
    this.setState({ loading: true });
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const currentLongitude = JSON.stringify(position.coords.longitude);
        const currentLatitude = JSON.stringify(position.coords.latitude);
        this.setState(
          {
            lat: currentLatitude,
            lng: currentLongitude,
          },
          () => {
            !(this.state.address) && this.getAddress(currentLatitude, currentLongitude);
          }, { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
        );
      },
      (error) => {
        this.setState({ loading: false });
        this.props.hideLoader();
      },
      {
        enableHighAccuracy: false,
        timeout: 30000,
        maximumAge: 0,
      }
    );
  };

  getAddress = (lat: any, lng: any) => {
    this.props.showLoader();
    var request = new XMLHttpRequest();
    var method = "GET";
    var url =
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      lat +
      "," +
      lng +
      "&sensor=true&key=AIzaSyBI5xkmKUlPoVouVxtAY2prU4ghXF5V0kc";
    var async = true;
    request.open(method, url, async);
    request.onreadystatechange = () => {
      if (request.readyState == 4 && request.status == 200) {
        var data = JSON.parse(request.responseText);
        this.props.hideLoader();
        var address = data.results[0].formatted_address || "";
        this.setState({ address: address });
      }
    };
    request.send();
  };

  validateImageFile = (fileExtension) => {
    var allowedExtension = ['jpeg', 'jpg', 'png', 'bmp', 'webp'];
    var isValidFile = true;

    for (var index in allowedExtension) {
      if (fileExtension.includes(allowedExtension[index])) {
        isValidFile = false;
        break;
      }
    }

    return isValidFile
  }

  handleImageChange = (event) => {
    if (this.validateImageFile(event.target.files[0].type)) {
      this.props.showNotification('Add the valid image file', 'error');
      return
    }

    this.setState({
      imageUri: event.target.files[0],
      fileName: event.target.files[0].name,
      fileType: event.target.files[0].type,
      file: event.target.files,
    });

    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        filePath: reader.result,
      });
    };
    reader.readAsDataURL(event.target.files[0]);
    event.target.value = '';
  };

  handleDeleteImage = () => {
    if (this.state.profileImagePath) {
      this.deleteProfileImageApiCall();
    } else if (this.state.imageUri) {
      this.deleteProfileImageApiCall();
    }
  };

  async componentDidMount() {
    // Customizable Area Start
    this.timer = setInterval(this.manageTimer, 1000);
    await StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          username: loginData.name || "",
          id: loginData.id,
          email: loginData.email,
          address: loginData.address || "",
          authToken: loginData.token,
          profileImagePath: loginData.profile_image_path,
          userType: loginData.user_type,
        });
      }
    });
    await this.requestLocationPermission();
  }
  _onProfileImageDelete = () => {
    const { openDialog } = this.props;
    const onClose = (msg) => {
      if (msg === 'Yes') {
        this.handleDeleteImage();
      }
    }
    this.props.openDialog('Remove profile picture', 'Are you sure want to delete profile image', onClose);
  };
  handleEmailVerification = () => {
    this.sendEmailOtp()
  }

  async componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  getOtp(OTP: string) {
    this.setState({ OTP });
  }
  manageTimer = () => {
    if (this.state.counter > 0) {
      this.setState(({ counter }) => ({
        counter: counter - 1
      }))
    }
    if (this.state.counter === 0) {
      clearInterval(this.timer)
    }
  }
  formatNumber = (number) => {
    return `${number.substring(0, 2)} ${number.substring(2, number.length)}`
  }
  render() {
    if (this.state.restartTimer) {
      this.setState({
        counter: 120
      });
      this.timer = setInterval(this.manageTimer, 1000);
      this.setState({
        restartTimer: false
      });
    }
    const { classes } = this.props;
    let image = <img src={profileIcon} style={{ cursor: "pointer" }} className={classes.profileImage} />;
    let isImgUpdated = image;
    if (this.state.profileImagePath) {
      image = <img
        src={this.state.profileImagePath}
        className={classes.profileImage}
      />
    } else if (this.state.filePath) {
      image = <img src={this.state.filePath} className={classes.profileImage} />
    }
    isImgUpdated = isImgUpdated === image;

    return (
      <Grid container direction="column" spacing={2} className={classes.parentCont}>
        <Grid item xs={10}>
          <p className={classes.accountInformationsTxt}>
            {configJSON.labelAccountInformation}
          </p>
          <p className={classes.edittProfileDetailTxt}>
            {configJSON.labelEditProfileDetail}
          </p>
        </Grid>

        <Grid
          xs={10}
          item
          container
          justify="flex-start"
          alignItems="stretch"
          spacing={2}
          className={classes.textMargin}
        >
          <div className={classes.tabImgContentLeft}>
            <Grid style={{ margin: "auto 15px" }}>
              <div className={classes.inputContainer}>
                {
                  this.state.isDeleted ?
                    <label className="btn-upload">
                      <input type='file' accept="image/*" placeholder="Upload Image" onChange={this.handleImageChange} />
                      <img src={cameraIcon} onChange={this.handleImageChange} />
                    </label>
                    :
                    <label className="btn-upload">
                      <input type='file' accept="image/*" placeholder="Upload Image" onChange={this.handleImageChange} />
                      <img src={cameraIcon} style={{ cursor: 'pointer' }} onChange={this.handleImageChange} />
                    </label>
                }
              </div>
            </Grid>
            <Grid style={{ margin: "auto 15px" }}>
              {
                image
              }
            </Grid>
            <Grid style={{ margin: "auto 15px" }}>
              <div>
                <img
                  src={deleteIcon}
                  style={{ cursor: "pointer" }}
                  onClick={() => !isImgUpdated && this._onProfileImageDelete()}
                />
              </div>
            </Grid>
          </div>
        </Grid>
        <Grid xs={10} item>
          <p className={`${classes.sofiaFont} ${classes.textMargin}`}>
            {configJSON.labelBasicInformation}
          </p>
        </Grid>

        <Grid
          xs={10}
          item
          container
          className={classes.textMargin}
        >
          <Grid
            xs={12}
            container
            direction="row"
            item
            spacing={1}
          >
            <Grid item xs={12} md={6} sm={6}>
              <Typography className={classes.textFieldLabel}>
                {configJSON.labelYourName}
              </Typography>
              <TextField
                type="text"
                fullWidth
                variant="outlined"
                onChange={(e) => this.onUsernameChanged(e)}
                value={this.state.username}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <Typography className={classes.textFieldLabel}>
                {configJSON.labelEmail}
              </Typography>
              <TextField
                type="text"
                fullWidth
                variant="outlined"
                onChange={(e) => this.onEmailChanged(e)}
                value={this.state.email}
                disabled={this.state.emailInputDisable}
              />
              <div style={{ textAlign: "right", marginTop: "10px", color: "rgb(50, 121, 95)" }}><span style={{ cursor: "pointer" }} onClick={() => this.handleEmailVerification()}>Verify and change</span></div>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.textFieldLabel}>
              {configJSON.labelEnterAddress}
            </Typography>
            <OutlinedInput
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e) => this.onAddressChanged(e)}
              endAdornment={
                <InputAdornment position="end">
                  <img
                    src={mapsIcons}
                    alt=""
                  />
                </InputAdornment>
              }
              value={this.state.address}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="space-around" item xs={10}>
          <Grid xs={10} item>
            <Button
              className={classes.saveButton}
              onClick={() => this.updateUserProfileCall()}
            >
              <Typography className={classes.saveButtonText}>
                Save Changes
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <div style={{ boxShadow: "0px 0px 30px 0px rgb(0,0,0,0.4)", textAlign: "center", display: this.state.showEmailOtpModal ? "block" : "none", padding: "30px", borderRadius: "20px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", backgroundColor: "#f7f7f7" }}>
          <Grid item xs={12} className={classes.rightBackground}>
            <Grid item xs={12} className={classes.parentContainer}>
              <Typography className={classes.checkYourSMSBoxTxt}>
                {`We have sent an OTP on your email ${this.state.email}`}
              </Typography>
              <Typography className={classes.otpTxt}>
                OTP
              </Typography>

              <OTPInputView OTPLength={4} value={this.state.OTP} className="" otpType={"number"}
                onOTPChange={(otp) =>
                  this.getOtp(otp)
                }
                secure={false} />
              <Typography className={classes.didntGetTheOTP}>
                {this.labelDidntGotOtp}
                {
                  this.state.counter == 0 ? "" : (
                    <span style={{ marginLeft: 7, textDecoration: "underline" }}>{`Resend In 0${Math.floor(this.state.counter / 60)}:${(this.state.counter % 60).toString().length > 1 ? this.state.counter % 60 : "0" + this.state.counter % 60}`}</span>
                  )
                }
                {
                  this.state.counter == 0 ? (
                    <span style={{ marginLeft: 7, textDecoration: "underline" }} onClick={() => {
                      this.sendEmailOtp()
                      this.setState({
                        restartTimer: true
                      });
                    }}>RESEND OTP</span>
                  ) : ""
                }
              </Typography>
              <Grid>
                <Typography className={classes.errorLabel}>
                  {this.state.otpError}
                </Typography>
              </Grid>

              <Button
                className={classes.saveButton}
                onClick={() => {
                  this.checkBothVerify()
                }}
              >
                <Typography className={classes.saveButtonTxt}>{
                  "Submit"
                }</Typography>
              </Button>
            </Grid>
          </Grid>
          <img onClick={() => this.setState({ showEmailOtpModal: false, OTP: "" })} src={closeIcon} style={{ cursor: "pointer", width: "25px", position: "absolute", top: "20px", right: "30px" }} alt="Close" />
        </div>
      </Grid>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    parentCont: {
      width: "95%",
      margin: "auto",
      overflowY: "hidden"
    },
    printIcon: {
      width: "50px",
    },
    sofiaFont: {
      fontFamily: APP_FONTS.SofiaProRegular
    },
    accountInformationsTxt: {
      marginTop: "50px",
      marginLeft: "30px",
      fontFamily: "SofiaPro",
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.36,
      letterSpacing: "normal",
      color: "#1e1f20",
    },
    edittProfileDetailTxt: {
      fontFamily: "SofiaPro",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      marginLeft: "30px",
      color: "#8f92a1",
    },
    textMargin: {
      marginLeft: "30px",
    },
    profileImage: {
      height: "90px",
      width: "90px",
      objectFit: "contain"
    },
    textField: {
      marginTop: "15px",
      marginLeft: "30px",
    },
    textFieldLabel: {
      fontFamily: "SofiaPro",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.67,
      letterSpacing: "1px",
      color: "#1e1f20;",
    },
    saveButton: {
      width: "319px",
      height: "58px",
      marginTop: "30px",
      bordeRadius: "12px",
      backgroundColor: "#32795f",
      padding: "10px",
      borderRadius: "12px",
      "&:hover": {
        backgroundColor: "#32795f",
      },
    },
    saveButtonText: {
      fontFamily: "SofiaPro",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#fff",
    },
    cameraIcon: {
      opacity: 0,
      position: "absolute",
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    inputContainer: {
      position: "relative",
    },
    rightBackground: {
      borderRadius: "32px",
      backgroundColor: "#f7f7f7"
    },
    parentContainer: {
      padding: "30px"
    },
    checkYourSMSBoxTxt: {
      fontFamily: "SofiaPro",
      fontSize: "16px",
      fonWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.38,
      letterSpacing: "normal",
      color: "#2f2f30"
    },
    otpTxt: {
      margin: "35px 0px 15px 0px",
      fontFamily: "SofiaPro",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.67,
      letterSpacing: "1px",
      color: "#1e1f20"
    },
    didntGetTheOTP: {
      fontFamily: 'SofiaPro',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      marginTop: 25,
      letterSpacing: '-0.35px',
      color: '#565656',
      cursor: "pointer"
    },
    errorLabel: {
      color: "red",
      marginTop: 15,
      fontFamily: "SofiaPro"
    },
    saveButton: {
      margin: "56px 2px 0 0",
      padding: "18px 143px 20px",
      borderRadius: "12px",
      backgroundColor: "#32795f",
      "&:hover": {
        backgroundColor: "#32795f"
      }
    },
    saveButtonTxt: {
      fontFamily: "SofiaPro",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff"
    },
    tabImgContentLeft: {
      // width: "85%",
      // margin: "10px auto",
      display: "flex",
      alignItems: "center",
    },
  });
const EditUserProfileWithConfirmBox = withConfirmBox(EditUserProfile);
const EditUserProfileWithLoader = withLoader(EditUserProfileWithConfirmBox)
const EditUserProfileWithToast = withToast(EditUserProfileWithLoader)
export default withRouter(withStyles(styles, { withTheme: true })(EditUserProfileWithToast));
