// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';
// Customizable Area Start
//import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
import {
    ToastAndroid,
    Platform
} from "react-native";
import { RouterProps  } from "react-router";
export const configJSON = require("./config");

export type Props = RouterProps & {
    id: string;
    classes: any;
    showNotification: any;
    showLoader: any;
    hideLoader: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    fullPhoneNumber: string;
    token: string;
    email: string;
    passcode: string;
    confirmedPasscode: string;
    otp: string;
    loading: boolean;
    timer: any;
    counter: number;
    otpError: string
    passcodError: string;
    confirmPasscodeError: string;
    matchPasscodeError: string;
    restartTimer : boolean;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ForgotPasswordVerifyOtpControllerWeb extends BlockComponent<
    Props,
    S,
    SS
    > {

    labelVerifyOtp: string;
    labelSaveBtn: string;
    labelOtp: string;
    labelVerifyOtpMessage: string;
    labelDidntGotOtp: string;
    labelOtpresendIn: string;
    apiVerifyOTPLCallId: String = "";
    apiSetPasscodeCallId: String = "";
    attrs: {};
    apiType: string;
    apiGetOTPLCallId : String;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.AlertMessage),
            // getName(MessageEnum.AccoutLoginSuccess)
        ]

        this.state = {
            otp: "",
            fullPhoneNumber: "",
            token: "",
            email: "",
            passcode: "",
            confirmedPasscode: "",
            timer: null,
            counter: 120,
            loading: false,
            enableField: false,
            otpError: "",
            passcodError: "",
            confirmPasscodeError: "",
            matchPasscodeError: "",
            restartTimer : false
        };
        // Customizable Area End
        this.labelVerifyOtp = configJSON.labelVerifyOtp;
        this.labelSaveBtn = configJSON.labelSaveBtn;
        this.labelOtp = configJSON.labelOtp;
        this.labelVerifyOtpMessage = configJSON.labelVerifyOtpMessage;
        this.labelDidntGotOtp = configJSON.labelDidntGotOtp;

        this.labelOtpresendIn = configJSON.labelOtpresendIn;
        this.labelDidntGotOtp = configJSON.labelDidntGotOtp;
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.AlertMessage) === message.id) {
            const title = message.getData(getName(MessageEnum.AlertTitleMessage));
            var AlertBodyMessage = message.getData(
              getName(MessageEnum.AlertBodyMessage)
            );
            this.props.showNotification(AlertBodyMessage, "error");
          } else if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + value
            );

            this.setState({ txtSavedValue: value });
        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.apiVerifyOTPLCallId) {
                this.setState({ loading: false });
                this.props.hideLoader();
                if (responseJson && responseJson.messages) {

                    this.setPasswordAPICall();

                } else {
                    this.parseApiErrorResponse(responseJson);
                    this.sendAPIFailMessage();
                } 
            }  else if (apiRequestCallId === this.apiGetOTPLCallId) {
                this.setState({ loading: false });
                this.props.hideLoader();
                if (responseJson && responseJson.meta && responseJson.meta.token) {
                    await this.setState({
                        restartTimer: true,
                        token:responseJson.meta.token,
                    });

                  this.props.showNotification("OTP Sent Successfully", "success");
                } else {
                  this.parseApiErrorResponse(responseJson);
                  // this.setState({errorMessage:  responseJson.errors[0]})
                  this.sendAPIFailMessage();
                }
              } else if (apiRequestCallId === this.apiSetPasscodeCallId) {
                this.setState({ loading: false });
                this.props.hideLoader();
                if (responseJson && responseJson.data && responseJson.data.id) {

                    await StorageProvider.remove('fpData')
                    this.props.showNotification('Passcode set successfully','success');
                    setTimeout(() => {
                        this.props.history.push('/PhoneLoginWeb');
                    }, 1000);                   

                } else {
                    this.parseApiErrorResponse(responseJson);
                    // this.setState({isError: true, errorMessage:  this.parseApiErrorResponse(responseJson)})
                    this.sendAPIFailMessage();
                }
            }
        }
    }
    async resendOTP() {
        this.setState({ loading: true });
        this.props.showLoader();
        this.attrs = {
          full_phone_number: this.state.fullPhoneNumber,
          user_type: this.props.history.location.state?.user_type
        };
    
        const header = {
          "Content-Type": configJSON.apiContentType,
        };
    
        const data = {
          attributes: this.attrs,
        };
        const httpBody = {
          data: data,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetOTPLCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.forgotPasscodeGetOTPAPiEndPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postAPiEndMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return;
      }

    sendAPIFailMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
        this.send(msg);
    }

    // Customizable Area Start
    // Customizable Area End
    //API TO VERIFY PHONE NUMBER
    validateOTPAndVerifyMobileCall() {

        let OTP: any = this.state.otp;
        const validateOtpError  = this.validateOTPNumber(OTP);

        if (validateOtpError) {
            this.setState({otpError: validateOtpError});
            return;
        }

        const validatePasscodeError = this.validatePasscode(this.state.passcode, "New Passcode");

        if (validatePasscodeError) {
            this.setState({passcodError: validatePasscodeError});
            return;
        }

        const validateConfirmPasscodeError = this.validateConfirmPasscode(this.state.confirmedPasscode, "Confirmed New Passcode");

        if (validateConfirmPasscodeError) {
            this.setState({confirmPasscodeError: validateConfirmPasscodeError});
            return;
        }

        if (this.state.passcode != this.state.confirmedPasscode) {
            this.setState({matchPasscodeError: "Both passcode doesn't match"});
            return;
        }


        this.setState({ loading: true });
        this.props.showLoader();
        const header = {
            "Content-Type": configJSON.apiContentType
        };
        const data = {
            token: this.state.token,
            otp_code: this.state.otp
        };
        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiVerifyOTPLCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.forgotPasscodeVerifyOTPAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiEndMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return;

    }

    //FINAL API CALL TO REGISTER USER
    setPasswordAPICall() {
        this.setState({ loading: true });
        this.props.showLoader();
        const header = {
            "Content-Type": configJSON.apiContentType
        };

        const data = {
            token: this.state.token,
            new_password: this.state.passcode
        };

        const httpBody = {
            data: data
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiSetPasscodeCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fpUpdatePasscodeAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiEndMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return;

    }


    validateOTPNumber(otp: string) {
        let error = null;
        if (!this.isNonNullAndEmpty(otp)) {
            error = "OTP " + configJSON.errorBlankField;
        } else if (!this.isValid4DigitPOTP(otp)) {
            error = configJSON.errorOTPNotValid;
        }
        return error;

    }

    isNonNullAndEmpty(value: String) {
        return (
            value !== undefined &&
            value !== null &&
            value !== "null" &&
            value.trim().length > 0
        );
    }

    isValid4DigitPOTP(value: String) {
        return (
            value !== undefined &&
            value !== null &&
            value !== "null" &&
            value.trim().length == 4
        );
    }
    notifyMessage(msg: string) {
        if (Platform.OS === 'android') {
            ToastAndroid.show(msg, ToastAndroid.SHORT)
        }
    }
    validatePasscode(otp: string, passcodeType: string) {
        let error = null;
        if (!this.isNonNullAndEmpty(otp)) {
            error = "Please set your 6 digit passcode";
        } else if (!this.isValid6DigitPasscode(otp)) {
            error = "Please set your 6 digit passcode";
        }
        return error;

    }
    validateConfirmPasscode(otp: string, passcodeType: string) {
        let error = null;
        if (!this.isNonNullAndEmpty(otp)) {
            error = "Please enter confirm passcode"
        } else if (!this.isValid6DigitPasscode(otp)) {
            error = "Please set your 6 digit confirm passcode"
        }
        return error;

    }

    isValid6DigitPasscode(value: String) {
        return (
            value !== undefined &&
            value !== null &&
            value !== "null" &&
            value.trim().length == 6
        );
    }
}
