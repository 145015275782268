//@ts-nocheck
import React from "react";
// Customizable Area End
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import withRouter from "react-router/withRouter";
import UserSupportsController, {
  Props
} from "./UserSupportsController.Web";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from '../../../framework/src/StorageProvider';
import { APP_COLORS } from '../../../components/src/AppColorConstants';
import { supportPhoneIcon } from "./assets";

class UserSupport extends UserSupportsController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area Start

  _supportMail = async () => {
    await this.supportsAPICall();
  }
  handleChangeInput = (event) => {
    this.setState({ emailError: false });
    this.setState({ messageError: false });
    this.setState({ [event.target.name]: event.target.value })
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    const res = await StorageProvider.get('loginData')
    if (res) {
      const loginData = JSON.parse(res);
      this.setState({
        authToken: loginData.token,
        userType: loginData.user_type,
        email: loginData.email,
        account_id: loginData.id
      });
    }
    // Customizable Area End
  }
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.rightContent}>
        <Grid item xs={11} className={classes.headingCont}>
          <div className={classes.mainContainerHead}>
            <div className={classes.headingLeft}>
              <Typography className={classes.selectFilesText}>Support</Typography>
            </div>
            <div className={classes.supportPhoneNumber}>
              <img src={supportPhoneIcon} className={classes.supportPhoneIcon} /> <a href="tel:+91-9319480924" className={classes.phColor}><p className="loginPx">+91-9319480924</p></a>
            </div>
          </div>
        </Grid>
        <Grid item xs={11} className={classes.inputDiv}>
          <Typography className={classes.inputLabel}>YOUR Email</Typography>
          <InputBase
            className={classes.emailInput}
            name="email"
            onChange={this.handleChangeInput}
            value={this.state.email}
            placeholder="Enter Your Email"
          />
          <p className={`error-label ${classes.sofiaFont}`}>
            {this.state.emailError}
          </p>
        </Grid>
        <Grid item xs={11} className={classes.inputDiv}>
          <Typography className={classes.inputLabel}>message</Typography>
          <InputBase
            className={classes.messageInput}
            multiline
            name="message"
            onChange={this.handleChangeInput}
            value={this.state.message}
            rows={8}
            style={{ fontSize: '20px' }}
            placeholder="Enter your message here"
          />
          <p className={`error-label ${classes.sofiaFont}`}>
            {this.state.messageError}
          </p>
        </Grid>
        <Grid item xs={11} className={classes.btnCont}>
          <Button className={classes.findPrintersBtn}
            onClick={this._supportMail}
            style={{ backgroundColor: '#32795f', fontSize: '14px' }}>SEND</Button>
        </Grid>
      </Grid>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => createStyles({
  parentCont: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex'
  },
  modalParent: {
    width: '100%',
    position: 'relative'
  },
  modalContent: {
    position: 'absolute',
    right: 0,
    borderRadius: '32px 0 0 32px',
    height: '100%',
    width: "90%",
    maxWidth:'400px',
    minWidth: 220,
    backgroundColor: APP_COLORS.app_theme_yellow_color
  },
  leftSideCont:
  {
    backgroundColor: APP_COLORS.app_theme_yellow_color,
  },
  rightSideCont:
  {
    background: '#f7f7f7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  totalCont: {
    width: '90%',
    margin: "auto"
  },
  rightContent: {
    position: "relative",
    minHeight: '80%',
    width: '100%',
    background: `#ffffff`,
    borderRadius: 12,
    marginBottom: 40
  },
  headingCont: {
    width: '100%',
    margin: "50px auto 0"
  },
  inputDiv: {
    margin: '10px auto'
  },
  selectFilesText: {
    fontSize: 22,
    margin: "20px 0 10px 0",
    fontFamily: APP_FONTS.SofiaProRegular,
    textAlign: 'left',
    color: '#1e1f20'
  },
  findPrintersBtn:
  {
    backgroundColor: '#32795f',
    color: 'white',
    borderRadius: 12,
    width: '90%',
    margin: '20px auto 30px 0',
    fontFamily: APP_FONTS.SofiaProRegular,
    height: 55,
    fontSize: "20px",
    maxWidth: 300,
  },
  backgroundImg:
  {
    background: '#f7f7f7',
  },
  contentLeft:
  {
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center'
  },
  docNav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  docNavImg: {
    height: 16,
    width: 16,
    marginRight: 5,
    objectFit: 'contain'
  },
  docTitle: {
    fontWeight: 'bold',
    fontSize: 14,
    fontFamily: APP_FONTS.SofiaProRegular,
    textAlign: 'center',
    color: '#1e1f20',
    margin: "10px 0"
  },
  docImg: {
    height: 50,
    width: 65,
    margin: "10px auto",
    objectFit: 'contain'
  },
  docContent: {
    fontSize: 14,
    fontFamily: APP_FONTS.SofiaProRegular,
    textAlign: 'center',
    color: '#8f92a1'
  },
  btnCont: {
    margin: 'auto',
    marginTop: '20px',
    borderRadius: 12,
  },
  qrCont: {
    margin: 'auto',
    marginTop: '20px',
    paddingLeft: 20
  },
  navBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: "20px 30px"
  },
  navSpan: {
    height: 45,
    width: 45,
    backgroundColor: '#32795f',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 14,
    marginLeft: 15,
    marginRight: 30
  },
  navSpanImg: {
    height: 20,
    width: 20,
    objectFit: 'contain'
  },
  userName: {
    fontFamily: APP_FONTS.SofiaProRegular,
    color: '#1e1f20'
  },
  inputLabel: {
    fontSize: 14,
    fontFamily: APP_FONTS.SofiaProRegular,
    textAlign: 'left',
    color: '#1e1f20',
    letterSpacing: 1,
    textTransform: 'uppercase',
    margin: "10px 0"
  },
  emailInput: {
    border: '1px solid rgba(143, 146, 161, 0.4)',
    padding: " 5px",
    borderRadius: 4,
    width: '90%',
    color: "#8f92a1",
    maxWidth: 450,
    fontFamily: APP_FONTS.SofiaProRegular,
  },
  messageInput: {
    border: '1px solid rgba(143, 146, 161, 0.4)',
    padding: "5px",
    borderRadius: 4,
    color: "#8f92a1",
    width: '90%',
    maxWidth: 450,
    fontFamily: APP_FONTS.SofiaProRegular,
  },
  mainContainerHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  headingLeft: {
    width: "100%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },

  supportPhoneNumber: {
    borderRadius: "12px",
    backgroundColor: "#f7f7f7",
    minWidth: "135px",
    padding: "10px",
    display: "flex",
    cursor: "pointer"
  },
  supportPhoneIcon: {
    height: "14px",
    width: "14px",
    marginRight: "3px",
    marginTop: "3px"
  },
  phColor: {
    color: "#1e1f20"
  }

});
// Customizable Area End
const UserSupportWithConfirmBox = withConfirmBox(UserSupport);
const UserSupportWithLoader = withLoader(UserSupportWithConfirmBox)
const UserSupportWithLoaderToast = withToast(UserSupportWithLoader)
export default withRouter(withStyles(styles, { withTheme: true })(UserSupportWithLoaderToast));