//@ts-nocheck
import React from "react";
import { APP_COLORS } from "../../../components/src/AppColorConstants";
import Select from "@material-ui/core/Select";
import { APP_FONTS } from "../../../components/src/AppFonts";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import InputBase from "@material-ui/core/InputBase";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import StorageProvider from "../../../framework/src/StorageProvider";
import withRouter from "react-router/withRouter";
import PagePrintSettingsController, {
  Props,
  configJSON,
} from "./SelectedDocumentsController.Web";
import DropZone from "./DropZone.Web";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  docExcel,
  docImage,
  docWord,
  maskIcon,
  docPdf,
  binIcon,
  docPowerPoint,
  colored,
  greyScale,
  portrait,
  textFileIcon,
  landScape,
} from "./assets";
const pageSizeArr = [
  { label: "A1", value: "A1" },
  { label: "A2", value: "A2" },
  { label: "A3", value: "A3" },
  { label: "A4", value: "A4" },
  { label: "Letter", value: "Letter" },
];

const printSidesArr = [
  { label: "One sided", value: "One", hidden: true },
  { label: "Both sided", value: "Both" },
];

class SelectedDocuments extends PagePrintSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  _backTapped = () => {
    this.props.history.goBack();
  };

  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          authToken: loginData.token,
          id: loginData.id,
        });
      }
    });
    await StorageProvider.get("selectedFiles").then((res) => {
      let selectedAtt = res !== undefined ? JSON.parse(res) : []
      if (selectedAtt?.length > 0) {
        this.setState({
          selectedFileList: selectedAtt,
        });
      }
    });
    await this.getAttachmentsAPICall();
    await this.getPaperTypes()
  }

  _saveChnagesTapped = (isAll: boolean) => {
    let printPageFrom: any = this.state.pagePropertiesForSelectedFile?.attributes.print_pages_from;
    let printPageTo: any = this.state.pagePropertiesForSelectedFile?.attributes.print_pages_to;
    let number_of_copies: any = this.state.pagePropertiesForSelectedFile?.attributes.number_of_copies;


    switch (true) {
      case (number_of_copies == undefined):
        this.props.showNotification("Please enter number of copies", "error")
        return;
      case (Number(number_of_copies) > 99):
        this.props.showNotification("Number of copies should be less than 100", "error")
        return;
      case (Number(number_of_copies) == 0):
        this.props.showNotification("Please enter valid number of copies", "error")
        return;
      case (printPageFrom == undefined):
        this.props.showNotification("Please enter print page start from", "error")
        return;
      case (Number(printPageFrom) == 0):
        this.props.showNotification("Please enter valid print page start from", "error")
        return;
      case (Number(printPageFrom) < 0):
        this.props.showNotification("Please enter valid print page start from");
        return;
      case (printPageTo == undefined):
        this.props.showNotification("Please enter print pages till", "error")
        return;
      case (Number(printPageTo) == 0):
        this.props.showNotification("Please enter valid print pages till", "error")
        return;
      case (Number(printPageTo) < 0):
        this.props.showNotification("Please enter valid print pages till", "error")
        return;
      case (Number(printPageFrom) > Number(printPageTo)):
        this.props.showNotification("Invalid start page number", "error")
        return;
    }


    const { id: attachmentId } = this.state.pagePropertiesForSelectedFile;
    const selectedFileList = [...this.state.selectedFileList];
    const editSelectedFileIndex = selectedFileList.findIndex((file) => { return file.id == attachmentId });
    const editSelectedFile = { ...selectedFileList[editSelectedFileIndex], attributes: { ...this.state.pagePropertiesForSelectedFile?.attributes } }
    selectedFileList[editSelectedFileIndex] = editSelectedFile;
    this.setState({ selectedFileList: selectedFileList, pagePropertiesModal: false }, () => {
      this.updatePrintProperties(isAll);
    })
  };
  BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 5,
      borderRadius: 15,
    },
    colorPrimary: {
      backgroundColor: "#CFDDD8",
    },
    bar: {
      borderRadius: 15,
      backgroundColor: "#32795f",
    },
  }))(LinearProgress);

  _saveAndApplyToAllDocs = async (isAll: boolean) => {
    let printPageFrom: any = this.state.pagePropertiesForSelectedFile?.attributes.print_pages_from;
    let printPageTo: any = this.state.pagePropertiesForSelectedFile?.attributes.print_pages_to;
    let number_of_copies: any = this.state.pagePropertiesForSelectedFile?.attributes.number_of_copies;

    if (isAll) {
      if (printPageFrom == undefined || printPageFrom == 0) {
        this.props.showNotification("Please enter print page start from", "error")
        return;
      }

      if (printPageTo == undefined || printPageTo == 0) {
        this.props.showNotification("Please enter valid print pages till", "error")
        return;
      }

      if (number_of_copies == undefined) {
        this.props.showNotification("Please enter number of copies", "error")
        return;
      }
      if (Number(number_of_copies) == 0) {
        this.props.showNotification("Please enter valid number of copies", "error")
        return;
      }
      if (Number(number_of_copies) > 99) {
        this.props.showNotification("Number of copies should be less than 100", "error")
        return;
      }

      let showError = false;
      await this.state.selectedFileList.forEach((element) => {
        if (element.attributes.total_attachment_pages < parseInt(this.state.pagePropertiesForSelectedFile?.attributes.print_pages_to)) {
          this.props.showNotification(`One of the document not having ${this.state.pagePropertiesForSelectedFile?.attributes?.print_pages_to} pages`, 'error');
          showError = true;
        }
      });
      if (showError) {
        return;
      }
    }

    const selectedFileList = [...this.state.selectedFileList];
    for (let i = 0; i < selectedFileList.length; i++) {
      const editSelectedFile = {
        ...selectedFileList[i], attributes:
        {
          ...selectedFileList[i].attributes,
          colour: this.state.pagePropertiesForSelectedFile?.attributes?.colour,
          layout: this.state.pagePropertiesForSelectedFile?.attributes?.layout,
          page_size: this.state.pagePropertiesForSelectedFile?.attributes?.page_size,
          print_pages_from: this.state.pagePropertiesForSelectedFile?.attributes?.print_pages_from,
          print_pages_to: this.state.pagePropertiesForSelectedFile?.attributes?.print_pages_to,
          print_sides: this.state.pagePropertiesForSelectedFile?.attributes?.print_sides,
          number_of_copies: this.state.pagePropertiesForSelectedFile?.attributes?.number_of_copies,
        }
      }
      selectedFileList[i] = editSelectedFile;
    }
    this.setState({ selectedFileList: selectedFileList, pagePropertiesModal: false }, () => {
      this.updatePrintProperties(isAll);
    })
  };

  handleImageChange = (files) => {
    this.setState(
      {
        uploadedFiles: [...files, ...this.state.uploadedFiles],
      },
      () => {
        this.uploadFile();
      }
    );
  }

  _handlePageSettingModal = async (file, e) => {
    e.preventDefault();
    this.setState({ pagePropertiesModal: !this.state.pagePropertiesModal, pagePropertiesForSelectedFile: { ...file, attributes: { ...file.attributes, print_pages_to: file.attributes.print_pages_to == 1 ? file.attributes.print_pages_to : file.attributes.print_pages_to } } })
    let allDocs: Array<number> = [];

    await this.state?.DataResponce?.forEach((element) => {
      allDocs?.push(element.attributes.total_attachment_pages);
    });

    await StorageProvider.remove("docPageCount");
    await StorageProvider.set("docPageCount", JSON.stringify(allDocs));

    await StorageProvider.get('docPageCount').then((res) => {
      const docPageCount = JSON.parse(res);
      if (docPageCount) {
        this.setState({ docsCount: docPageCount });
      }
    });
  };
  _handleContinueModal = (e) => {
    e.preventDefault();
    this.setState({ continueModal: !this.state.continueModal })
  };

  handleDelete = (id, event) => {
    event.preventDefault();
    const { openDialog } = this.props;
    const onClose = (msg) => {
      if (msg === 'Yes') {
        this.deleteAttachMent(id);
      }
    }
    this.props.openDialog('Delete', 'Are you sure, you want to delete?', onClose);
  }

  handleDeleteAll = () => {
    event.preventDefault();
    const { openDialog } = this.props;
    const onClose = (msg) => {
      if (msg === 'Yes') {
        this.deleteAllAttachMents();
      }
    }
    this.props.openDialog('Delete', 'Are you sure, you want to delete all files?', onClose);
  }

  handleInputChange = (event, value) => {
    if (event.target.name === "print_pages_to") {
      if (parseInt(event.target.value) > this.state.pagePropertiesForSelectedFile.attributes.total_attachment_pages) {
        this.props.showNotification(`Document having ${this.state.pagePropertiesForSelectedFile.attributes.total_attachment_pages} page only`, 'error');
        return false
      }
    }

    if (event.target.name === "number_of_copies") {
      if (parseInt(event.target.value) == 0 || event.target.value == undefined) {
        this.props.showNotification(`Please enter valid numner of copies`, 'error');
        return false
      }
    }

    if (event.target.name === "print_pages_from") {
      if (parseInt(event.target.value) > this.state.pagePropertiesForSelectedFile.attributes.print_pages_to) {
        this.props.showNotification("Print start page number should be less than or equal to end page number", 'error');
        return false
      }
    }

    if (event.target.name === "print_pages_from" && event.target.value.length > 4) { }
    else if (event.target.name === "print_pages_to" && event.target.value.length > 4) { }
    else {
      const newitem = { ...this.state.pagePropertiesForSelectedFile, attributes: { ...this.state.pagePropertiesForSelectedFile?.attributes, [event.target.name]: (event.target.value || value) } };
      this.setState({ pagePropertiesForSelectedFile: newitem });
    }

    if (event.target.name === "page_types") {
      this.setState({
        selectedPaperType: event.target.value
      })
    }
  }

  handleContinueGenerateBarcode = async () => {
    const { openDialog } = this.props;
    const onClose = (msg) => {
      if (msg === 'Yes') {
        this.createOrder();
      }
    }
    this.props.openDialog('Place Order?', 'Are you sure, you want to place the order?', onClose);
  }


  handleContinueToPayment = () => {
    if (this.props?.location?.state?.calledFrom === "NearByShopsToPay" || this.props?.location?.state?.calledFrom === "PaymentScreen") {
      this.props.history.push('/UserSidebarNavigation/UploadDashBoard/Payment');
    }
    else {
      //  need to call create order once submited for payment
      //  this.createOrder()
      this?.props?.history?.push('/UserSidebarNavigation/UploadDashBoard/Payment');
    }
  }
  handleContinue = async () => {

    if (this.isSettingAppliedToAllFile()) {
      let data: any = {};
      data.show_pay_button = true;
      await StorageProvider.remove("showPay");
      await StorageProvider.set("showPay", JSON.stringify(data));
      const options = {
        pathname: '/UserSidebarNavigation/UploadDashBoard/SelectShop',
        state: { calledFrom: "SelectedDocumentsScreen" }
      }
      this.props.history.push(options)
    }
  }
  isSettingAppliedToAllFile = () => {
    let isAppliedToAll = false;
    let unAppliedSettingFileName = ""
    for (let index = 0; index < this.state.selectedFileList.length; index++) {
      const file = this.state.selectedFileList[index];
      if (file && file.attributes) {
        if (file?.attributes.colour && file?.attributes.layout && file?.attributes.number_of_copies && file?.attributes.print_sides && file?.attributes.print_pages_from && file?.attributes.print_pages_to) {
          isAppliedToAll = true;
        } else {
          unAppliedSettingFileName = file?.attributes.file_name;
          isAppliedToAll = false;
          break;
        }
      }
    }
    if (!isAppliedToAll) {
      this.props.showNotification("Please apply setting for " + unAppliedSettingFileName, "warn")
    }

    return isAppliedToAll;
  }
  fileType(fileType: String) {
    const ImageallowedExtensions = /(jpg|jpeg|png|gif|svg)$/i;
    const pptAllowedExtension = /(ppt|pptx)$/i;
    const docAllowedExtension = /(doc|docx)$/i;
    const pdfAllowedExtension = /(pdf)$/i;
    const excelAllowedExtension = /(xlsx|xls|csv)$/i;
    if (ImageallowedExtensions.exec(fileType)) {
      return docImage;
    } else if (pptAllowedExtension.exec(fileType)) {
      return docPowerPoint;
    } else if (docAllowedExtension.exec(fileType)) {
      return docWord;
    } else if (pdfAllowedExtension.exec(fileType)) {
      return docPdf;
    } else if (excelAllowedExtension.exec(fileType)) {
      return docExcel;
    }
    return docExcel;
  }

  fileIcons = {
    'application/msword': require('../assets/docword.png'),
    'application/vnd.oasis.opendocument.text': require('../assets/docword.png'),
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': require('../assets/docword.png'),
    'image/gif': require('../assets/image_placeholder.jpg'),
    'image/png': require('../assets/image_placeholder.jpg'),
    'image/jpg': require('../assets/image_placeholder.jpg'),
    'image/jpeg': require('../assets/image_placeholder.jpg'),
    'application/pdf': require('../assets/pdf_placeholder.png'),
    'application/vnd.ms-powerpoint': require('../assets/ppt.png'),
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': require('../assets/ppt.png'),
    'application/vnd.oasis.opendocument.presentation': require('../assets/ppt.png'),
    'text/plain': textFileIcon,
    'application/vnd.ms-excel': require('../assets/docexcel.png'),
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': require('../assets/docexcel.png'),
    'application/vnd.oasis.opendocument.spreadsheet': require('../assets/docexcel.png'),
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid container className={classes.rightContent}>
          <Grid item xs={12} className={classes.headingCont}>
            <Typography className={classes.selectFilesText}>
              {configJSON.selectFilesText}
            </Typography>
          </Grid>
          {
            this.state.selectedFileList.length > 0 &&
            <Button
              className={classes.deleteAllBtn}
              onClick={() => this.handleDeleteAll()}
              style={{
                backgroundColor: "transparent",
                color: "#32795f",
                fontWeight: 'bold'
              }}
            >
              <img
                title="Delete All Attachments"
                src={binIcon}
                alt=""
                className={classes.docNavImg}
              />
              {configJSON.deleteAllText}
            </Button>
          }
          <Grid container spacing={4} className={classes.docsCont}>
            {this.state.uploadedFiles.length > 0 ? this.state.uploadedFiles.map((file) => {
              return (
                <Grid key={file.id} item xs={12} sm={6} md={3}>
                  {
                    file.type === ('application/msword' || file.type === 'application/vnd.oasis.opendocument.text' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                      || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'application/pdf'
                      || file.type === 'text/plain' || file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') || (file.type === 'application/vnd.oasis.opendocument.presentation'
                        || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.type === 'application/vnd.oasis.opendocument.spreadsheet'
                      ) ?
                      <Grid item xs={12} className={classes.eachDocs}>
                        <img src={this.fileIcons[file.type] || require('../assets/pdf_placeholder.png')} className={classes.docImg} />
                        <Typography className={classes.docTitle}>
                          {file?.attributes?.file_name}
                        </Typography>
                        <Typography className={classes.docTitle}>
                          {this.state.uploading ? "Uploading..." : null}
                        </Typography>
                        <Typography className={classes.docContent}>
                          {this.state.uploading} &nbsp; %
                        </Typography>
                        <div className={classes.progressCont}>
                          <this.BorderLinearProgress
                            variant="determinate"
                            value={this.state.uploading}
                            className={classes.progressBar}
                          />
                        </div>
                      </Grid>
                      : null
                  }
                </Grid>
              );
            }) : null}
            {this.state.selectedFileList.map((file) => {
              return (
                <Grid key={file.id} item xs={12} sm={6} md={3}>
                  <Grid item xs={12} className={file?.attributes?.colour ? classes.eachDocs : classes.eachDocsSettingNotApply}>
                    <div className={classes.docNav}>
                      <img
                        src={maskIcon}
                        alt=""
                        className={classes.docNavImg}
                        onClick={this._handlePageSettingModal.bind(this, file)}
                      />
                      <img
                        src={binIcon}
                        alt=""
                        className={classes.docNavImg}
                        onClick={this.handleDelete.bind(this, file.id)}
                      />
                    </div>
                    <Grid className={classes.uploadedFile}>
                      <div style={{ margin: '10px' }}>
                        <img src={this.fileIcons[file.attributes.content_type] || require('../assets/pdf_placeholder.png')} className={classes.docImg} />
                      </div>
                      <div>
                        <Typography className={classes.docTitle}>
                          {file?.attributes?.file_name ? file?.attributes?.file_name : ""}
                        </Typography>
                        <Typography className={classes.docContent}>
                          All Pages - {file?.attributes?.colour ? file?.attributes?.colour : "Please select setting"}
                        </Typography>
                        <Typography className={classes.docContent}>
                          {file?.attributes?.layout && file?.attributes?.layout} - {file?.attributes?.page_size && file?.attributes?.page_size}
                        </Typography>
                        <Typography className={classes.docContent}>
                          {file?.attributes?.print_pages_to === 1 ? file?.attributes?.total_pages ?? "1" : file?.attributes?.total_pages ?? "1"} {file?.attributes?.total_pages > 1 ? "pages" : "page"}
                        </Typography>
                        <Typography className={classes.docContent}>
                          Copies - {file?.attributes?.number_of_copies ? file?.attributes?.number_of_copies : "1"}
                        </Typography>
                        <Typography className={classes.docContent}>
                          {file?.attributes?.paper_type}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ cursor: "pointer" }}
          >
            <DropZone
              onSelectFile={this.handleImageChange}
              multiple={true}
            />
          </Grid>
          <Grid item xs={12} className={classes.btnCont}>
            {this.state.selectedFileList.length > 0 && <Button
              className={classes.findPrintersBtn}
              style={{ backgroundColor: "#32795f", margin: '40px' }}
              onClick={this._handleContinueModal}
            >
              {configJSON.continuePrintBtnTxt}
            </Button>}
          </Grid>
        </Grid>
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.pagePropertiesModal}
          onClose={this._handlePageSettingModal.bind(this, this.state.pagePropertiesForSelectedFile)}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
          className={classes.modalParent}
        >
          <div className={classes.modalContent}>
            <div className={classes.modalHeadingCont}>
              <Typography className={classes.modalHeading}>
                {configJSON.printProperties}
              </Typography>
            </div>
            <div className={classes.modalContentCont}>
              <Typography className={classes.modalContentText}>
                {configJSON.copies}
              </Typography>
              <div className={classes.pageInputCont}>
                <InputBase
                  onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                  className={classes.pageInputModal} type={"Number"} value={this.state?.pagePropertiesForSelectedFile?.attributes?.number_of_copies} name="number_of_copies" onChange={(event) => { this.handleInputChange(event) }} />
              </div>
              <Typography className={classes.modalContentText}>
                {configJSON.pagesToPrint}
              </Typography>
              <div className={classes.colorModalDiv}>
                <div className={classes.pageInputCont}>
                  <InputBase
                    onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                    className={classes.pageInputModal} type={"Number"} value={this.state?.pagePropertiesForSelectedFile?.attributes?.print_pages_from} name="print_pages_from" onChange={(event) => { this.handleInputChange(event) }} />
                </div>
                <Typography className={classes.modalToText}>
                  {configJSON.pagesTo}
                </Typography>
                <div className={classes.pageInputCont}>
                  <InputBase
                    onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                    className={classes.pageInputModal} type={"Number"} value={this.state?.pagePropertiesForSelectedFile?.attributes?.print_pages_to} name="print_pages_to" onChange={(event) => { this.handleInputChange(event) }} />
                </div>
              </div>
              <Typography className={classes.modalContentText}>
                {configJSON.Colors}
              </Typography>
              <div className={classes.colorModalDiv}>
                <span className={classes.spanModalImg}>
                  <img src={colored} name="colour" value={'Colored'} alt="" style={this.state?.pagePropertiesForSelectedFile?.attributes?.colour === 'Colored' ? { border: "1px solid rgba(143, 146, 161, 1)" } : null} className={classes.colorCopy} onClick={(event) => { this.handleInputChange(event, 'Colored') }} />
                  <p style={this.state?.pagePropertiesForSelectedFile?.attributes?.colour === 'Colored' ? { color: "#32795F" } : null} >
                    Colored
                  </p>
                </span>
                <span className={classes.spanModalImg}>
                  <img src={greyScale} name="colour" value={'Greyscale'} alt="" className={classes.colorCopy} style={this.state?.pagePropertiesForSelectedFile?.attributes?.colour === 'Greyscale' ? { border: "1px solid rgba(143, 146, 161, 1)" } : null} onClick={(event) => { this.handleInputChange(event, 'Greyscale') }} />
                  <p style={this.state?.pagePropertiesForSelectedFile?.attributes?.colour === 'Greyscale' ? { color: "#32795F" } : null} >
                    Greyscale
                  </p>
                </span>
              </div>
              <Typography className={classes.modalContentText}>
                {configJSON.Layout}
              </Typography>
              <div className={classes.colorModalDiv}>
                <span className={classes.spanModalImg}>
                  <img src={portrait} alt="" name="layout" style={this.state?.pagePropertiesForSelectedFile?.attributes?.layout === 'Portrait' ? { border: "1px solid rgba(143, 146, 161, 1)" } : null} value={'Portrait'} className={classes.paperCopy} onClick={(event) => { this.handleInputChange(event, 'Portrait') }} />
                  <p style={this.state?.pagePropertiesForSelectedFile?.attributes?.layout === 'Portrait' ? { color: "#32795F" } : null} >
                    Portrait
                  </p>
                </span>
                <span className={classes.spanModalImg}>
                  <img src={landScape} alt="" name="layout" style={this.state?.pagePropertiesForSelectedFile?.attributes?.layout === 'Landscape' ? { border: "1px solid rgba(143, 146, 161, 1)" } : null} value={'Landscape'} className={classes.paperCopy} onClick={(event) => { this.handleInputChange(event, 'Landscape') }} />
                  <p style={this.state?.pagePropertiesForSelectedFile?.attributes?.layout === 'Landscape' ? { color: "#32795F" } : null} >
                    Landscape
                  </p>
                </span>
              </div>
              <Typography className={classes.modalContentText}>
                {configJSON.pageSize}
              </Typography>
              <Select value={this.state?.pagePropertiesForSelectedFile?.attributes?.page_size} name="page_size" onChange={(event) => { this.handleInputChange(event) }}
                input={<InputBase className={classes.selectInputModal} />}
              >
                {pageSizeArr.map((item) => (
                  <MenuItem key={item.value} style={{ fontSize: '14px' }} value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
              <Typography className={classes.modalContentText}>
                {configJSON.printSlides}
              </Typography>
              <Select value={this.state?.pagePropertiesForSelectedFile?.attributes?.print_sides} name="print_sides" onChange={(event) => { this.handleInputChange(event) }}
                input={<InputBase className={classes.selectInputModal} />}
              >
                {printSidesArr.map((item) => (
                  <MenuItem key={item.value} style={{ fontSize: '14px' }} value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
              <Typography className={classes.modalContentText}>
                {configJSON.pageType}
              </Typography>

              <Select value={this.state?.selectedPaperType} name="page_types" onChange={(event) => { this.handleInputChange(event) }}
                input={<InputBase className={classes.selectInputModal} />}
              >
                {
                  this.state?.listOfPaperType?.filter(y => y !== 'All').map(x => {
                    return <MenuItem key={x} style={{ fontSize: '14px' }} value={x}>{x}</MenuItem>
                  })
                }
              </Select>
              <Button
                className={classes.closeModalBtn}
                style={{ backgroundColor: "#32795f", marginTop: 20 }}
                onClick={() => { this._saveChnagesTapped(false) }}
              >
                {configJSON.saveBtn}
              </Button>
              <Button
                className={classes.closeModalBtn}
                onClick={() => { this._saveAndApplyToAllDocs(true) }}
                style={{
                  backgroundColor: "transparent",
                  color: "#32795f",
                  marginTop: 10,
                  marginBottom: 50,

                }}
              >
                {configJSON.applyBtn}
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.continueModal}
          onClose={this._handleContinueModal}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
          className={classes.modalParent}
        >
          <div className={classes.modalContent}>
            <div className={classes.continueModalContentCont}>
              <div className={classes.btnModalCont}>
                <Button
                  className={classes.closeModalBtn}
                  style={{ backgroundColor: "#32795f" }}
                  onClick={this.handleContinueGenerateBarcode}
                >
                  {configJSON.generateBarCode}
                </Button>
                <Button
                  className={classes.closeModalBtn}
                  onClick={this.handleContinue}
                  style={{
                    backgroundColor: "transparent",
                    color: "#32795f",
                    marginTop: 20
                  }}
                >
                  {configJSON.findShopsBtn}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    rightSideCont: {
      background: "#f7f7f7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    totalCont: {
      width: "90%",
      margin: "auto",
    },
    rightContent: {
      position: "relative",
      zIndex: "10",
      minHeight: "80%",
      width: "100%",
      backgroundImage: `url(${require("../assets/bg.jpeg")})`,
      borderRadius: 12,
      marginBottom: 40,
    },
    backImg: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: "0",
    },
    headingCont: {
      width: "100%",
    },
    uploadedFile: {
      [theme.breakpoints.down("xs")]: {
        display: 'flex'
      },
      [theme.breakpoints.between("sm", "md")]: {
        display: 'contents'
      },
      "@media (min-width: 1280px)": {
        display: 'contents'
      }
    },
    selectFilesText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: 16,
        left: "20px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: 22,
        left: "75px",
      },
      "@media (min-width: 1280px)": {
        fontSize: 22,
        left: "75px",
      },
      margin: "20px 0 0px 20px",
      fontWeight: "normal",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    findPrintersBtn: {
      backgroundColor: "#32795f",
      color: "white",
      borderRadius: 12,
      [theme.breakpoints.down("xs")]: {
        width: "75%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "90%",
      },
      "@media (min-width: 1280px)": {
        width: "90%",
      },
      margin: "20px auto 0 0",
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
      fontSize: 14
    },
    backgroundImg: {
      background: "#f7f7f7",
    },
    contentLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    docsCont: {
      [theme.breakpoints.down("xs")]: {
        width: "89%",
        margin: "10px auto",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "95%",
        margin: "20px auto",
      },
      "@media (min-width: 1280px)": {
        width: "95%",
        margin: "20px auto",
      }

    },
    eachDocs: {
      border: "solid 2px rgba(143, 146, 161, 0.2)",
      borderRadius: 12,

      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        padding: "10px 10px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        padding: "20px 10px",
      },
      "@media (min-width: 1280px)": {
        padding: "20px 10px",
      }
    },
    eachDocsSettingNotApply: {
      border: "solid 2px rgba(143, 146, 161, 0.2)",
      borderRadius: 12,
      padding: "20px 10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      opacity: '0.5'
    },
    docNav: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    docNavImg: {
      height: 20,
      width: 20,
      cursor: "pointer",
      marginRight: 5,
      objectFit: "contain",
    },
    descCont: {
      display: "flex",
      borderRadius: 12,
      backgroundColor: "white",
      padding: "10px 20px",
      width: "90%",
      margin: "auto",
    },
    eachDesc: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "auto",
      marginTop: "20px",
    },
    uploadCont: {
      margin: "auto",
      marginTop: "20px",
      border: "2px dotted #77c285",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 12,
    },
    uploadLabel: {
      width: "100%",
    },
    uploadInput: {
      color: "transparent",
    },
    uploadImg: {
      height: 130,
      width: 130,
      margin: "auto",
      objectFit: "contain",
    },
    uploadText: {
      fontSize: 22,
      width: "80%",
      margin: "20px auto",
      fontWeight: "normal",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",
    },
    docTitle: {
      fontWeight: "bold",
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#1e1f20",

      wordBreak: 'break-all',
      [theme.breakpoints.down("xs")]: {
        margin: "5px 0",
      },
      [theme.breakpoints.between("sm", "md")]: {
        margin: "10px 0",
      },
      "@media (min-width: 1280px)": {
        margin: "10px 0",
      }
    },
    docImg: {
      height: 50,
      width: 65,
      margin: "10px auto",
      objectFit: "contain",
    },
    docContent: {
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "center",
      color: "#8f92a1",
      [theme.breakpoints.down("xs")]: {
        lineHeight: '1.2'
      },
      [theme.breakpoints.between("sm", "md")]: {
      },
      "@media (min-width: 1280px)": {
      }
    },
    btnCont: {
      margin: "auto",
      // marginTop: "20px",
      borderRadius: 12,
    },
    navBar: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "20px 30px",
    },
    navSpan: {
      height: 45,
      width: 45,
      backgroundColor: "#32795f",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 14,
      marginLeft: 15,
      marginRight: 30,
    },
    navSpanImg: {
      height: 20,
      width: 20,
      objectFit: "contain",
    },
    userName: {
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    //Modal Styling
    modalParent: {
      width: "100%",
      position: "relative",
    },
    modalContent: {
      position: "absolute",
      right: 0,
      borderRadius: "32px 0 0 32px",
      height: "auto",
      width: "90%",
      maxWidth: '400px',
      minWidth: 270,
      height: "100vh",
      overflowY: "auto",
      backgroundColor: APP_COLORS.app_theme_yellow_color,
    },
    modalContentCont: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    continueModalContentCont: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: 'center',
      justifyContent: 'center'
    },
    btnModalCont: {
      width: "100%",
      display: "flex",
      alignItems: 'center',
      flexDirection: "column",
      justifyContent: 'center'
    },
    modalHeadingCont: {
      display: "flex",
      margin: "20px 0",
      alignItems: "flex-end",
      justifyContent: "flex-start",
    },
    modalHeading: {
      fontSize: 22,
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      color: "#1e1f20",
    },
    modalToText: {
      fontSize: 13,
      fontFamily: APP_FONTS.SofiaProRegular,
      textTransform: "uppercase",
      color: "#1e1f20",
      marginLeft: 20,
    },
    modalContentText: {
      fontSize: 13,
      letterSpacing: 1,
      margin: "20px 0 10px 20px",
      fontFamily: APP_FONTS.SofiaProRegular,
      textAlign: "left",
      textTransform: "uppercase",
      color: "#1e1f20",
    },
    closeModalBtn: {
      border: "2px solid #32795f",
      color: "white",
      borderRadius: 12,
      width: "90%",
      margin: " auto",
      fontSize: 14,
      fontFamily: APP_FONTS.SofiaProRegular,
      height: 55,
      maxWidth: 300,
    },
    deleteAllBtn: {
      border: "2px solid #32795f",
      color: "white",
      borderRadius: 12,
      marginLeft: " auto",
      fontSize: 11,
      fontFamily: APP_FONTS.SofiaProRegular,
    },
    spanModalImg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: 20,
      marginRight: 20,
      fontSize: 16,
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
    },
    colorModalDiv: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    pageInputCont: {
      border: "1px solid rgba(143, 146, 161, 0.4)",
      width: 88,
      height: 48,
      borderRadius: 4,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      marginLeft: 20,
      justifyContent: "center",
    },
    pageInputModal: {
      border: "none",
      outline: "none",
      width: "50%",
      margin: "auto",
      fontSize: "1.2rem",
      color: "#1e1f20",
      fontFamily: APP_FONTS.SofiaProRegular,
    },
    selectInputModal: {
      width: "90%",
      // height:48,
      padding: "5px 10px",
      marginLeft: 20,
      fontFamily: APP_FONTS.SofiaProRegular,
      border: "1px solid rgba(143, 146, 161, 0.4)",
      color: "#1e1f20",
      borderRadius: 4,
      backgroundColor: "transparent",
    },
    paperCopy: {
      height: 45,
      width: 60,
      cursor: "pointer",
      marginBottom: 7,
      padding: '5px',
      objectFit: "contain",
    },
    colorCopy: {
      height: 50,
      cursor: "pointer",
      marginBottom: 7,
      width: 50,
      padding: '5px',
      objectFit: "contain",
    },
    borderImage: {
      border: "1px solid rgba(143, 146, 161, 0.4)"
    },
    progressCont: {
      width: "calc(100% - 14px)",
      display: "flex",
      alignItems: "center",
      height: 30,
      justifyContent: "space-between",
    },
    progressCancel: {
      width: 18,
      height: 18,
      objectFit: "contain",
      marginLeft: 10,
      cursor: "pointer",
    },
    progressBar: {
      width: "90%",
      margin: "10px auto",
    },
  });
const SelectTedDocumentWithConfirmBox = withConfirmBox(SelectedDocuments);
const SelectTedDocumentWithLoader = withLoader(SelectTedDocumentWithConfirmBox)
const SelectTedDocumentWithToast = withToast(SelectTedDocumentWithLoader)
export default withRouter(withStyles(styles, { withTheme: true })(SelectTedDocumentWithToast));

