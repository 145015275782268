import React, { Component,Suspense } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { withRouter } from "react-router";
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import './app.css';
import PrivateRoute from "../../components/src/PrivateRoute.Web";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import BhimUpiIntegration from '../../blocks/BhimUpiIntegration/src/BhimUpiIntegration';
import StripeIntegration from '../../blocks/StripeIntegration/src/StripeIntegration';
import DocumentDistribution from '../../blocks/DocumentDistribution/src/DocumentDistribution';
import MenuOrdering from '../../blocks/MenuOrdering/src/MenuOrdering';
import TaxCalculator from '../../blocks/TaxCalculator/src/TaxCalculator';
import Location from '../../blocks/Location/src/Location';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import LandingPage from '../../blocks/LandingPage/src/LandingPage.Web';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import QrCodes from '../../blocks/QrCodes/src/QrCodes';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import Sms from '../../blocks/Sms/src/Sms';
import TwofactorAuthentication from '../../blocks/TwofactorAuthentication/src/TwofactorAuthentication';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import Maps from '../../blocks/Maps/src/Maps';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import SignUp from '../../blocks/PhoneLogin/src/SignUp.web';
import VerifyOtp from '../../blocks/PhoneLogin/src/VerifyOtp.Web';
import PhoneLoginWeb from '../../blocks/PhoneLogin/src/PhoneLogin.Web';
import ForgotPasscode from '../../blocks/PhoneLogin/src/ForgotPasscode.Web';
import ForgotPasswordVerifyOtp from '../../blocks/PhoneLogin/src/ForgotPasswordVerifyOtp.Web';
import EditUserProfile from '../../blocks/user-profile-basic/src/EditUserProfileBlock.Web';
import UserSettings from '../../blocks/user-profile-basic/src/UserSettings.Web';
import ChangePhoneNumber from '../../blocks/user-profile-basic/src/ChangePhoneNumberBlock.Web';
import ChangePasscode from '../../blocks/user-profile-basic/src/ChangePasscodeBlock.Web';
import ChangePhoneOTPVerify from '../../blocks/user-profile-basic/src/ChangePhoneOTPVerify.Web';
import UploadDashBoard from '../../blocks/UserDashBoard/src/UploadDashBoard.Web';
import UserSidebarNavigation from '../../blocks/UserDashBoard/src/UserSidebarNavigation.Web';
import NearByShopsToPay from '../../blocks/Maps/src/NearByShopsToPay.Web';
import MapFilter from '../../blocks/Maps/src/NearByShops.Web';
import PaymentSuccessful from "../../blocks/UserDashBoard/src/PaymentSuccessful.Web";
// import UserOrdersHistory from '../../blocks/OrderManagement/src/UserOrdersHistory.Web';
import OrderDetailsQR from '../../blocks/OrderManagement/src/OrderDetailsQR.Web';
import Logout from "../../blocks/user-profile-basic/src/Logout.web";
import UserSupport from "../../blocks/user-profile-basic/src/UserSupport.Web";
import ShopDashboard from '../../blocks/MerchantDashBoard/src/ShopDashboard.Web';
import EditMerchantBusinessDetails from '../../blocks/user-profile-basic/src/EditMerchantBusinessDetails.Web';
import EditPayoutDetails from '../../blocks/user-profile-basic/src/EditPayoutDetails.Web';
import AddPayoutDetails from '../../blocks/user-profile-basic/src/AddPayoutDetails.Web';
import MerchantSidebarNavigation from "../../blocks/MerchantDashBoard/src/MerchantSidebarNavigation.Web";
import ScannedOrderDetails from "../../blocks/OrderManagement/src/ScannedOrderDetails.Web";
import ContactUs from "../../blocks/LandingPage/src/ContactUs.Web";
import PrivacyPolicy from "../../blocks/LandingPage/src/PrivacyPolicy.Web";
import TermsOfService from "../../blocks/LandingPage/src/TermsOfService.Web";
import CookiePolicy from "../../blocks/LandingPage/src/CookiePolicy.Web";
import AboutUs from "../../blocks/LandingPage/src/AboutUs.Web";
import EndUserLicence from "../../blocks/LandingPage/src/EndUserLicence.Web";
import Faq from "../../blocks/LandingPage/src/Faq.Web";
import Services from "../../blocks/LandingPage/src/Services.Web";
import ServicesDetails from "../../blocks/LandingPage/src/ServiceDetails.Web";
import CookieConsent, { Cookies } from "react-cookie-consent";
import MerchantWallet from "../../blocks/user-profile-basic/src/MerchantWallet.Web";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const UserOrdersHistory = React.lazy(() => import('../../blocks/OrderManagement/src/UserOrdersHistory.Web'));

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
}));

const routeMap = {
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  PhoneLoginWeb: {
    component: PhoneLoginWeb,
    path: '/PhoneLoginWeb'
  },
  UploadDashBoard: {
    component: UploadDashBoard,
    path: '/UploadDashBoard'
  },
  SignUp: {
    component: SignUp,
    path: '/SignUp'
  },
  VerifyOtp: {
    component: VerifyOtp,
    path: '/VerifyOtp'
  },
  ForgotPasscode: {
    component: ForgotPasscode,
    path: '/ForgotPasscode'
  },
  ForgotPasswordVerifyOtp: {
    component: ForgotPasswordVerifyOtp,
    path: '/ForgotPasswordVerifyOtp'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  BhimUpiIntegration: {
    component: BhimUpiIntegration,
    path: '/BhimUpiIntegration'
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: '/StripeIntegration'
  },
  DocumentDistribution: {
    component: DocumentDistribution,
    path: '/DocumentDistribution'
  },
  MenuOrdering: {
    component: MenuOrdering,
    path: '/MenuOrdering'
  },
  TaxCalculator: {
    component: TaxCalculator,
    path: '/TaxCalculator'
  },
  Location: {
    component: Location,
    path: '/Location'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  QrCodes: {
    component: QrCodes,
    path: '/QrCodes'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Sms: {
    component: Sms,
    path: '/Sms'
  },
  TwofactorAuthentication: {
    component: TwofactorAuthentication,
    path: '/TwofactorAuthentication'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  Maps: {
    component: Maps,
    path: '/Maps'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  // EditUserProfileBlock:{
  //   component:EditUserProfileBlock,
  //  path:"/EditUserProfileBlock"},
  UserSettings: {
    component: UserSettings,
    path: '/UserSettings'
  },
  ChangePhoneNumber: {
    component: ChangePhoneNumber,
    path: '/ChangePhoneNumber'
  },
  Home: {
    component: SignUp,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};
const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

(function() {

  var beforePrint = function() {
      console.log('Functionality to run before printing.');
  };

  var afterPrint = function() {
      console.log('Functionality to run after printing');
  };

  if (window.matchMedia) {
      var mediaQueryList = window.matchMedia('print');
      mediaQueryList.addListener(function(mql) {
          if (mql.matches) {
              beforePrint();
          } else {
              afterPrint();
          }
      });
  }

  window.onbeforeprint = beforePrint;
  window.onafterprint = afterPrint;

}());


class App extends Component {

  render() {
    const getPath = window.origin;

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/PhoneLoginWeb" component={PhoneLoginWeb} />
            {/* <Route exact path="/UserOrdersHistory" component={UserOrdersHistory} /> */}
            <Route exact path="/OrderDetailsQR" component={OrderDetailsQR} />
            <Route exact path="/UserSupport" component={UserSupport} />
            <Route exact path="/ScannedOrderDetails" component={ScannedOrderDetails} />
            <Route exact path="/LandingPage" component={LandingPage} />
            <Route exact path="/Contact-us" component={ContactUs} />
            <Route exact path="/Privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/Terms-and-conditions" component={TermsOfService} />
            <Route exact path="/Cookies-policy" component={CookiePolicy} />
            <Route exact path="/About-us" component={AboutUs} />
            <Route exact path="/Enduser-licence" component={EndUserLicence} />
            <Route exact path="/Faq" component={Faq} />
            <Route exact path="/Services" component={Services} />
            <Route exact path="/Service-details" component={ServicesDetails} />
            <Route exact path="/VerifyOtp"  >
              <VerifyOtp></VerifyOtp>
            </Route>
            <Route exact path="/ForgotPasscode"  >
              <ForgotPasscode></ForgotPasscode>
            </Route>
            <Route
              exact
              path="/ForgotPasswordVerifyOtp"
            >
              <ForgotPasswordVerifyOtp></ForgotPasswordVerifyOtp>
            </Route>
            <Route exact path="/SignUp" >
                <SignUp></SignUp>
            </Route>
            <PrivateRoute role="Customer" path="/UserSidebarNavigation">
              <UserSidebarNavigation />
            </PrivateRoute>
            <PrivateRoute role="Customer" path="/UserSettings">
              <UserSidebarNavigation />
            </PrivateRoute>
            <PrivateRoute role="Customer" path="/UserSupport">
              <UserSidebarNavigation />
            </PrivateRoute>
            <PrivateRoute role="Customer" path="/OrderManagement">
              <UserSidebarNavigation />
            </PrivateRoute>
            <PrivateRoute role="Merchant" path="/MerchantSidebarNavigation">
              <MerchantSidebarNavigation />
            </PrivateRoute>
            <PrivateRoute role="Merchant" path="/MerchantSettings">
              <MerchantSidebarNavigation />
            </PrivateRoute>
            <PrivateRoute role="Merchant" path="/MerchantSupport">
              <MerchantSidebarNavigation />
            </PrivateRoute>
            <PrivateRoute role="Merchant" path="/MerchantWallet">
              <MerchantSidebarNavigation />
            </PrivateRoute>
            <PrivateRoute role="Merchant" path="/MerchantOrderManagement">
              <MerchantSidebarNavigation />
            </PrivateRoute>
            <PrivateRoute exact role="Merchant" path="/Merchant/AddMerchantBusinessDetails">
              <EditMerchantBusinessDetails mode="create"/>
            </PrivateRoute>
            <PrivateRoute exact role="Merchant" path="/Merchant/AddMerchantPayoutDetails">
              <AddPayoutDetails />
            </PrivateRoute>
            <Redirect exact from="/PhoneLoginWeb" to="/"></Redirect>
            <Suspense fallback= {
            <div >
              <CircularProgress />
            </div>
           }
          >
             <section>
            {/* <Route exact path="/UserOrdersHistory" component={UserOrdersHistory} /> */}
            </section>
            </Suspense>
          </Switch>
        </Router>
        <CookieConsent
          overlay
          enableDeclineButton
          declineButtonText="Decline" style={{ position: "relative", display:"block", padding:"5px", lineHeight:"25px" }}
        >
        <p className="cokee-quote">
         Welcome to printsec.app! In order to provide a more relevant experience for you, we use cookies to enable some website functionality. Cookies help us see which articles most interest you; allow you to easily share articles on social media; permit us to deliver content, jobs and ads tailored to your interests and locations; and provide many other site benefits. For more information, please review our
          <a href={`${getPath}/Cookies-policy`} target="_blank"> <span className="cookiePolicyHead">Cookie Policy</span></a></p>
        </CookieConsent>
      </div>
    );
  }
}
export default withRouter(App);