// @ts-nocheck
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  logoutIcon,
  phoneIcon,
  arrowRightIcon,
  loginPasscodeIcon,
  profileIcon,
  editPhoneColorIcon,
  editProfileColorIcon,
  loginPasscodeColorIcon,
  editProfileIcon,
  arrowRightBlack,
  A1,
  arrowDown,
  Letter,
  A2,
  A3,
  A4,
  colorPallet,
  greyscalePallet,
  arrowUp,
} from "./assets";
import { APP_FONTS } from "../../../components/src/AppFonts";
import StorageProvider from "../../../framework/src/StorageProvider";
import UserSettingsController, {
  Props,
  configJSON,
} from "./UserSettingsController.Web";
import Switch from "react-router-dom/Switch";
import Redirect from "react-router/Redirect";
import withRouter from "react-router/withRouter";
import PrivateRoute from "../../../components/src/PrivateRoute.Web";
import EditUserProfile from "../../../blocks/user-profile-basic/src/EditUserProfileBlock.Web";
import ChangePhoneNumber from "../../../blocks/user-profile-basic/src/ChangePhoneNumberBlock.Web";
import ChangePasscode from "../../../blocks/user-profile-basic/src/ChangePasscodeBlock.Web";
import ChangePhoneOTPVerify from "../../../blocks/user-profile-basic/src/ChangePhoneOTPVerify.Web";
import { withConfirmBox } from "../../../components/src/withConfirmBox.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import { withToast } from "../../../components/src/withSnackBar.Web";
import TermsAndCondition from "./TermsAndConditionBlock.Web";
import PrivacyPolicyBlock from "./PrivacyPolicyBlock.Web";
import SoftwareInformation from "./SoftwareInformation.Web";
import LicenseAgreement from './LicenseAgreement.Web'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

class UserSettings extends UserSettingsController {
  constructor(props: Props) {
    super(props);
    this.scrollRef = React.createRef();
  }



  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    await StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          username: loginData.name,
          profileImagUri: loginData.profile_image_path,
          user_type: loginData.user_type,
          authToken: loginData.token,
          id: loginData.id,
          phone: loginData.phone,
          email: loginData.email,
        });
      }
    });
    await StorageProvider.get("login_type").then((res) => {
      this.setState({
        account_type: res,
      });
      if (res === 'guest_account') {
        this.props.history.push('PrivacyPolicy')
      }
    });
    this.getBasePrices()
  }

  refreshStorageData = async () => {
    StorageProvider.get("loginData").then((res) => {
      const loginData = JSON.parse(res);
      if (loginData) {
        this.setState({
          username: loginData.name,
          profileImagUri: loginData.profile_image_path,
          user_type: loginData.user_type
        });
      }
    });
  };

  isActiveLink = (pathName) => {
    let { location } = this.props;
    const path = location.pathname.toString();
    if (path.includes(pathName)) {
      return true;
    }
    if (pathName == this.state.selectedPath) {
      return true;
    }
    return false;
  };

  navigationLinkGuest =
    [
      // {
      //   name: "Edit Profile Details",
      //   path: "/UserSettings/Settings/EditUserProfile",
      //   currentPath: "EditUserProfile",
      //   img: editProfileColorIcon,
      //   unImg: editProfileIcon,
      // },
      // {
      //   name: "Change Phone Number",
      //   path: "/UserSettings/Settings/ChangePhoneNumber",
      //   currentPath: "ChangePhoneNumber",
      //   img: editPhoneColorIcon,
      //   unImg: phoneIcon,
      // },
      // {
      //   name: "Change Login Passcode",
      //   path: "/UserSettings/Settings/ChangePasscode",
      //   currentPath: "ChangePasscode",
      //   img: loginPasscodeColorIcon,
      //   unImg: loginPasscodeIcon,
      // },
      {
        name: "Print Prices",
        path: "/PrintPrices",
        currentPath: "PrintPrices",
        img: require("../assets/payout_details.png"),
        unImg: require("../assets/payout_details.png"),
      },
      // {
      //   name: "Delete My Account",
      //   path: "/DeleteMyAccount",
      //   currentPath: "DeleteMyAccount",
      //   img: require("../assets/delete_account.png"),
      //   unImg: require("../assets/delete_account.png"),
      // },
      {
        name: "Logout",
        path: "/logout",
        currentPath: "Logout",
        img: logoutIcon,
        unImg: logoutIcon,
      }
    ]
  navigationLinkUser =
    [
      {
        name: "Edit Profile Details",
        path: "/UserSettings/Settings/EditUserProfile",
        currentPath: "EditUserProfile",
        img: editProfileColorIcon,
        unImg: editProfileIcon,
      },
      {
        name: "Change Phone Number",
        path: "/UserSettings/Settings/ChangePhoneNumber",
        currentPath: "ChangePhoneNumber",
        img: editPhoneColorIcon,
        unImg: phoneIcon,
      },
      {
        name: "Change Login Passcode",
        path: "/UserSettings/Settings/ChangePasscode",
        currentPath: "ChangePasscode",
        img: loginPasscodeColorIcon,
        unImg: loginPasscodeIcon,
      },
      {
        name: "Print Prices",
        path: "/PrintPrices",
        currentPath: "PrintPrices",
        img: require("../assets/payout_details.png"),
        unImg: require("../assets/payout_details.png"),
      },
      {
        name: "Delete My Account",
        path: "/DeleteMyAccount",
        currentPath: "DeleteMyAccount",
        img: require("../assets/delete_account.png"),
        unImg: require("../assets/delete_account.png"),
      },
      {
        name: "Logout",
        path: "/logout",
        currentPath: "Logout",
        img: logoutIcon,
        unImg: logoutIcon,
      }
    ]

  deleteMyAccountConfirmation() {
    const { openDialog } = this.props;
    const onClose = (msg: any) => {
      if (msg === "Yes") {
        this.sendMobileOtp()
        // this.accountDeleteApiCall();
      } else {
        this.setState({ selectedPath: "" })
      }
    };
    this.props.openDialog(
      "Delete Account Confirmation",
      "Are you sure want to delete account?",
      onClose
    );
  }
  logoutConfirmationDialog = () => {
    const { openDialog } = this.props;
    const onClose = (msg: any) => {
      if (msg === 'Yes') {
        this.clearStorageAndredirectToLogin()
      } else {
        this.setState({ selectedPath: "" })
      }
    }
    this.props.openDialog('Logout Confirmation', 'Are you sure want to logout?', onClose);
  }

  handleSelectPageForPrice(paperType) {
    this.setState({
      selectedPaperTypes: this.state.basePrintPrices.filter(x => {
        return x.page_size === paperType
      })
    })
  }

  showPricesDialog = () => {
    this.setState({
      priceModal: true
    })
  }
  bottomNavigationLinks = [
    {
      label: "Privacy Policy",
      path: "/UserSettings/Settings/PrivacyPolicy"
    },
    {
      label: "Terms & Conditions",
      path: "/UserSettings/Settings/TermsCondition"
    },
    {
      label: "End User License Agreement",
      path: "/UserSettings/Settings/LicenseAgreement"
    },
    {
      label: "Additional Software Information Copyright",
      path: "/UserSettings/Settings/SoftwareInformation"
    }
  ]

  clearStorageAndredirectToLogin = async () => {
    const keys = await StorageProvider.getKeys();
    await StorageProvider.removeMultiple(keys)
    await StorageProvider.set("user_type", this.state.user_type)
    this.props.history.push("/PhoneLoginWeb");
  };


  navigateTo = (pathName) => {
    if (pathName === "/logout") {
      this.logoutConfirmationDialog()
    } else if (pathName === "/DeleteMyAccount") {
      this.deleteMyAccountConfirmation();
    } else if (pathName === "/PrintPrices") {
      // this.getBasePrices()
      this.showPricesDialog()
    } else {
      this.props.history.push(pathName);
    }
    this.scrollRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }

  closeModal = () => {
    this.setState({
      priceModal: false,
      selectedPath: ""
    })
  }


  // Customizable Area End
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.leftSidebar}>
        <Grid item sm={4} xs={12} className={classes.leftSidebar}>
          <Grid container justify={'space-between'} className={classes.parentContainer}>
            <Grid item xs={6}>
              <p className={classes.labelSetting}>{configJSON.labelSetting}</p>
            </Grid>
            <Grid item xs>
              {this.state.profileImagUri ? (
                <img
                  src={this.state.profileImagUri}
                  className={classes.settingAvatar}
                />
              ) : (
                <img src={profileIcon} style={{ cursor: "pointer" }} className={classes.settingAvatar} />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justify={"space-between"}
            alignItems="center"
          >
            {
              (this.state.account_type === 'guest_account' ? this.navigationLinkGuest : this.navigationLinkUser).map((navItem) => {
                return this.isActiveLink(navItem.currentPath) ?
                  <Grid key={navItem.name} item xs={10} className={classes.editProfileColorButton} onClick={() => {
                    this.navigateTo(navItem.path)
                    this.setState({ selectedPath: navItem.currentPath })

                  }}>
                    <img
                      src={navItem.img}
                      className={`${classes.sofiaFont}  ${classes.editProfileButtonIcon}`}
                    />
                    <span
                      to={navItem.path}
                      className={`${classes.sofiaFont}  ${classes.editProfileColorButtonTxt}`}
                    >
                      {navItem.name}
                    </span>
                    <img
                      src={arrowRightIcon}
                      className={classes.editProfileArrowIcon}
                    /></Grid> :
                  <Grid key={navItem.name} item xs={10} className={classes.editProfileButton} onClick={() => {
                    this.navigateTo(navItem.path)
                    this.setState({ selectedPath: navItem.currentPath })

                  }}>
                    <img
                      src={navItem.unImg}
                      className={classes.editProfileButtonIcon}
                    />
                    <span
                      to={navItem.path}
                      className={`${classes.sofiaFont}  ${classes.editProfileButtonTxt}`}
                    >
                      {navItem.name}
                    </span>
                    <img
                      src={arrowRightBlack}
                      className={classes.editProfileArrowIcon}
                    /></Grid>
              })
            }
          </Grid>
          {
            this.bottomNavigationLinks.map((item, index) =>
              <Typography key={index} className={classes.tabBottomText} style={index === 0 ? { marginTop: 30 } : null} onClick={() => { this.navigateTo(item.path) }} >{item.label}<img src={arrowRightBlack} alt="" className={classes.tabNextIcon} /></Typography>
            )
          }
        </Grid>
        <Grid item sm={8} xs={12} className={`${classes.rightBackground}`} ref={this.scrollRef}>
          <Switch>
            <PrivateRoute role="Customer" exact path={`/UserSettings/Settings/EditUserProfile`}>
              <EditUserProfile />
            </PrivateRoute>
            <PrivateRoute role="Customer" exact path={`/UserSettings/Settings/ChangePhoneNumber`}>
              <ChangePhoneNumber />
            </PrivateRoute>
            <PrivateRoute role="Customer" exact path={`/UserSettings/Settings/ChangePasscode`}>
              <ChangePasscode />
            </PrivateRoute>
            <PrivateRoute role="Customer" exact path={`/UserSettings/Settings/ChangePhoneOTPVerify`}>
              <ChangePhoneOTPVerify />
            </PrivateRoute>
            <PrivateRoute
              role="Customer"
              exact
              path={`/UserSettings/Settings/TermsCondition`}
            >
              <TermsAndCondition />
            </PrivateRoute>
            <PrivateRoute
              role="Customer"
              exact
              path={`/UserSettings/Settings/LicenseAgreement`}
            >
              <LicenseAgreement />
            </PrivateRoute>
            <PrivateRoute
              role="Customer"
              exact
              path={`/UserSettings/Settings/PrivacyPolicy`}
            >
              <PrivacyPolicyBlock />
            </PrivateRoute>
            <PrivateRoute
              role="Customer"
              exact
              path={`/UserSettings/Settings/SoftwareInformation`}
            >
              <SoftwareInformation />
            </PrivateRoute>
            <Redirect
              exact
              from={"/UserSettings/Settings"}
              to={this.state.account_type === 'guest_account' ? "/UserSettings/Settings/PrivacyPolicy" : "/UserSettings/Settings/EditUserProfile"}
            ></Redirect>
          </Switch>
        </Grid>
        <Dialog
          open={this.state.priceModal}
          onClose={this.closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" >
            <span style={{ fontFamily: APP_FONTS.SofiaProRegular }}>{this.state.title}</span>
          </DialogTitle>
          <DialogContent style={{height: '476px'}}>
            <DialogContentText id="alert-dialog-description" style={{ fontFamily: APP_FONTS.SofiaProRegular }}>
              <div>
                <p style={{
                  fontSize: "16px",
                  color: "#1e1f20",
                }}>{configJSON.printDetailsOfPrintSec}</p>
                <p style={{
                  fontSize: "14px",
                  fontWeight: 500
                }}>{configJSON.bothSides}</p>
                <table style={{ width: '100%', fontSize: '13px', overflow: 'auto' }}>
                  {
                    this.state.basePrintPrices?.filter((a, i) => this.state.basePrintPrices?.findIndex((s) => a.page_size === s.page_size) === i).sort((a, b) => {
                      return a.page_size.localeCompare(b.page_size)
                    }).map(x => {
                      return <>
                        <tr key={x} style={{
                          margin: '3% auto',
                          display: 'flex',
                          padding: '13px',
                          alignItems: 'center',
                          borderRadius: '12px',
                          justifyContent: 'space-between',
                          boxShadow: '0px 0px 3px 0px',
                          width: '100%'
                        }}

                        >
                          <th>
                            <div style={{ height: '40px', width: '40px', backgroundColor: '#EEEFF1', padding: '8px', borderRadius: '5px' }}>
                              <img
                                src={x.page_size == 'A1' ? A1 : x.page_size == 'A2' ? A2 : x.page_size == 'A3' ? A3 : x.page_size == "A4" ? A4 : Letter}
                                style={{ height: '26px', width: '23px' }}
                              />
                            </div>
                          </th>
                          <th>
                            <p style={{
                              fontSize: "16px",
                              color: "#1e1f20",
                            }}>{x.page_size} Page</p>
                          </th>
                          {
                            this.state.selectedPaperTypeForPrice === x.page_size ?
                              <th onClick={() => {
                                this.setState({
                                  selectedPaperTypeForPrice: ""
                                })
                                this.handleSelectPageForPrice("")
                              }}
                                style={{ cursor: 'pointer' }}>
                                <img
                                  src={arrowUp}
                                  style={{ height: '15px', width: '15px' }}
                                />
                              </th>
                              :
                              <th onClick={() => {
                                this.setState({
                                  selectedPaperTypeForPrice: x.page_size
                                })
                                this.handleSelectPageForPrice(x.page_size)
                              }}
                                style={{ cursor: 'pointer' }}>

                                <img
                                  src={arrowDown}
                                  style={{ height: '15px', width: '15px' }}
                                />
                              </th>
                          }
                        </tr>
                        {
                          this.state.selectedPaperTypeForPrice === x.page_size &&

                          this.state.selectedPaperTypes.filter((a, i) => this.state.selectedPaperTypes?.findIndex((s) => (a.paper_type === s.paper_type)) === i).map(y => {
                            return <>
                              <tr>
                                <td>{y.paper_type}</td>
                              </tr>
                              <tr style={{
                                cursor: 'pointer',
                                margin: '3% auto',
                                display: 'flex',
                                padding: '13px',
                                alignItems: 'center',
                                borderRadius: '12px',
                                justifyContent: 'space-between',
                                boxShadow: '0px 0px 3px 0px',
                                width: '100%'
                              }}>

                                <tr>
                                  <td>
                                    <img
                                      src={colorPallet}
                                      style={{ height: '30px', width: '30px' }}
                                    />
                                    {" "}Colored
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p style={{
                                      fontWeight: 600,
                                      margin: 0,
                                      color: '#32795F'
                                    }}>₹{y.single_side?.colored}</p>
                                    <p
                                      style={{
                                        fontWeight: 600,
                                        margin: 0,
                                        color: '#32795F'
                                      }}
                                    >Per Page</p>
                                  </td>
                                </tr>
                              </tr>
                              <tr style={{
                                cursor: 'pointer',
                                margin: '3% auto',
                                display: 'flex',
                                padding: '13px',
                                alignItems: 'center',
                                borderRadius: '12px',
                                justifyContent: 'space-between',
                                boxShadow: '0px 0px 3px 0px',
                                width: '100%'
                              }}>
                                <tr>
                                  <td>
                                    <img
                                      src={greyscalePallet}
                                      style={{ height: '30px', width: '30px' }}
                                    />
                                    {" "}Greyscaled
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <td>
                                      <p
                                        style={{
                                          fontWeight: 600,
                                          margin: 0,
                                          color: '#32795F'
                                        }}
                                      >₹{y.single_side?.greyscale}</p>
                                      <p
                                        style={{
                                          fontWeight: 600,
                                          margin: 0,
                                          color: '#32795F'
                                        }}
                                      >Per Page</p>
                                    </td>
                                  </td>
                                </tr>
                              </tr>
                            </>
                          })
                        }
                      </>
                    })
                  }
                </table>
              </div>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              style={{ fontFamily: APP_FONTS.SofiaProRegular, color: '#32795f' }}
              onClick={() => {
                this.closeModal()
              }}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid >
    );
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const styles = (theme: Theme) =>
  createStyles({
    leftSidebar: {
      // backgroundColor: "#f7f7f7",
    },
    rightBackground: {
      borderRadius: "32px",
      backgroundColor: "#fff",
    },
    sofiaFont: {
      fontFamily: APP_FONTS.SofiaProRegular
    },
    printIcon: {
      width: "50px",
    },
    logoutIcon: {
      width: "48px",
      height: "48px",
      margin: "180px 11px 0 7px",
      objectFit: "contain",
    },
    labelSetting: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.8,
      letterSpacing: "normal",
      color: "#1e1f20",
    },
    settingAvatar: {
      width: "48px",
      height: "48px",
      borderRadius: "16px",
      float: "right",
    },
    parentContainer: {
      padding: "16px",
    },
    editProfileColorButton: {
      width: "90%",
      margin: "3% auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      padding: "17px ",
      borderRadius: "12px",
      backgroundColor: "#32795f",
    },
    editProfileButton: {
      width: "90%",
      margin: "3% auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      cursor: "pointer",
      padding: "17px",
      borderRadius: "12px",
      backgroundColor: "#fff",
      boxShadow: '0px 0px 3px 0px'
    },
    editProfileButtonIcon: {
      width: "40px",
      height: "40px",
      position: 'relative'
    },
    editProfileColorButtonTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      width: "70%",
      marginLeft: "15px",
      textAlign: "left",
      lineHeight: 1,
      letterSpacing: "normal",
      color: "#ffffff",
      textAlign: "left",
      textTransform: "capitalize",
      textDecoration: "none",
    },
    editProfileButtonTxt: {
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      width: "70%",
      marginLeft: "15px",
      textAlign: "left",
      lineHeight: 1,
      letterSpacing: "normal",
      color: "#8f92a1",
      textTransform: "capitalize",
      textDecoration: "none",
    },
    editProfileArrowIcon: {
      width: "18px",
      margin: '0.8rem',
      height: "18px",
    },
    tabBottomText: {
      fontSize: 14,
      width: "80%",
      cursor: "pointer",
      fontFamily: APP_FONTS.SofiaProRegular,
      color: "#1e1f20",
      margin: "10px auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    endUserfTxt: {
      marginTop: "180px",
      marginLeft: "25px",
      fontFamily: APP_FONTS.SofiaProRegular,
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.38,
      letterSpacing: "normal",
      color: "#1e1f20",
    },
    accountInformationsTxt: {
      marginTop: "50px",
      marginLeft: "30px",
      fontFamily: APP_FONTS.SofiaPro,
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.36,
      letterSpacing: "normal",
      color: "#1e1f20",
    },
    edittProfileDetailTxt: {
      fontFamily: APP_FONTS.SofiaPro,
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      marginLeft: "30px",
      color: "#8f92a1",
    },
    textMargin: {
      marginLeft: "30px",
    },
    profileImage: {
      height: "50px",
      width: "50px",
    },
    textField: {
      marginTop: "15px",
      marginLeft: "30px",
    },
    textFieldLabel: {
      fontFamily: APP_FONTS.SofiaPro,
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.67,
      letterSpacing: "1px",
      color: "#1e1f20;",
    },
    saveButton: {
      bordeRadius: "12px",
      backgroundColor: "#32795f",
      marginLeft: "30px",
      marginTop: "50px",
      padding: "18px 108px 20px 109px",
      borderRadius: "12px",
      "&:hover": {
        backgroundColor: "#32795f",
      },
    },
    saveButtonText: {
      fontFamily: APP_FONTS.SofiaPro,
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#fff",
    },
    active: {
      color: "red",
    },
  });
const UserSettingsWithConfirmBox = withConfirmBox(UserSettings);
const UserSettingsWithLoader = withLoader(UserSettingsWithConfirmBox)
const UserSettingsWithToast = withToast(UserSettingsWithLoader)

export default withRouter(
  withStyles(styles, { withTheme: true })(UserSettingsWithToast)
);