import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
export type Props = StyledProps & RouterProps & {
  id: string;
  openDialog: any;
  showNotification: any;
  showLoader: any;
  hideLoader: any;
  mode: 'create' | 'edit';
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  authToken: string;
  id: number;
  //merchantid: number;
  error: string | null;
  pages: string[];
  paper_types: string[];
  print_colours: string[];
  selectedPage: string;
  selectedPageType: string;
  modalOpen: boolean;
  setPriceClr: boolean;
  setPriceGry: boolean;
  baseMerchantPrices: any;
  basePrices: any;
  valueClr: number;
  valueGry: number;
  admin_commission_for_coloured: any;
  admin_commission_percentage_for_coloured: any;
  admin_commission_percentage_for_greyscale: any;
  admin_commission_for_greysacle: any
}

interface SS {
  id: any;
}

export default class AddPrintPricesController extends BlockComponent<
  Props,
  S,
  SS
> {
  phoneReg: RegExp;
  ifscReg: RegExp;
  apiType: string = "";
  attrs: any = {};
  emailReg: RegExp;
  upiReg: RegExp;
  apiGetMerchantPriceApiCallID: String = '';
  apiGetBasePriceApiCallID: String = '';
  apiPostMerchantPriceCallId: String = '';

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.AlertMessage)
    ]

    this.state = {
      loading: false,
      authToken: "",
      id: 0,
      error: null,
      pages: [],
      paper_types: [],
      print_colours: [],
      selectedPage: '',
      selectedPageType: '',
      modalOpen: false,
      setPriceClr: false,
      setPriceGry: false,
      baseMerchantPrices: {},
      basePrices: {},
      valueClr: 0,
      valueGry: 0,
      admin_commission_for_coloured: null,
      admin_commission_percentage_for_coloured: null,
      admin_commission_percentage_for_greyscale: null,
      admin_commission_for_greysacle: null,
    };
    // Customizable Area End
    this.phoneReg = new RegExp(/^[0-9]+$/);
    this.emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.ifscReg = /^[A-Za-z]{4}\d{7}$/;
    this.upiReg = /^\w.+@\w+$/;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(
        getName(MessageEnum.AlertTitleMessage)
      );

      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showNotification(AlertBodyMessage, 'error');

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiGetMerchantPriceApiCallID) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          await this.setState({
            baseMerchantPrices: responseJson?.data[0].attributes.print_prices.data,
            pages: responseJson?.data[0].attributes.page_types,
            paper_types: responseJson?.data[0].attributes.paper_types,
            print_colours: responseJson?.data[0].attributes.print_colours?.map((x: any) => {
              return x.toLowerCase()
            }),
            admin_commission_percentage_for_coloured: responseJson?.data[0].attributes.admin_commission_percentage_for_coloured,
            admin_commission_for_coloured: responseJson?.data[0].attributes.admin_commission_for_coloured,
            admin_commission_percentage_for_greyscale: responseJson?.data[0].attributes.admin_commission_percentage_for_greyscale,
            admin_commission_for_greysacle: responseJson?.data[0].attributes.admin_commission_for_greysacle,

          });
        }
        else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }

      if (apiRequestCallId === this.apiGetBasePriceApiCallID) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          await this.setState({ basePrices: responseJson?.data });
        }
        else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }

      if (apiRequestCallId === this.apiPostMerchantPriceCallId) {
        this.setState({ loading: false });
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.getMerchantPrices()
          this.setState({ modalOpen: false })
        }
        else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }


  // Customizable Area End
  //STRAT --here we are validating the phone number and country code and making API call to get OTP to verify user 

  async getMerchantPrices() {

    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

    };

    this.setState({ loading: true });
    this.props.showLoader();


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetMerchantPriceApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getMerchantPrintPrice}?id=${this.state.id}`)


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  async getBasePrices() {

    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken,
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

    };

    this.setState({ loading: true });
    this.props.showLoader();


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetBasePriceApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getBasePrintPrices}`)


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  

  }

  async postMerchantPrice() {

    let clrValue: any = this.state.valueClr;
    let gryValue: any = this.state.valueGry;

    if (this.state.admin_commission_for_coloured >= clrValue) {
      this.props.showNotification("Print price of color page should be more than platform fee", "error")
      return false;
    }

    if (this.state.admin_commission_for_greysacle >= gryValue) {
      this.props.showNotification("Print price of greyscale page should be more than platform fee", "error")
      return false;
    }

    if (gryValue.toString().split(".")[0].length > 3) {
      this.props.showNotification("Print price of greyscale page should be less than 999", "error")
      return false;
    }

    if (clrValue.toString().split(".")[0].length > 3) {
      this.props.showNotification("Print price of color page should be less than 999", "error")
      return false;
    }

    let formData = new FormData();
    formData.append('token', this.state.authToken);

    if (
      this.state.print_colours.includes("greyscale") && this.state.print_colours.includes("colored")
    ) {
      formData.append('data[print_price][0][page_size]', this.state.selectedPage);
      formData.append('data[print_price][0][paper_type]', this.state.selectedPageType);
      formData.append('data[print_price][0][colors]', 'greyscale');
      formData.append('data[print_price][0][single_side_price]', this.state.valueGry.toString());
      formData.append('data[print_price][0][double_side_price]', '0');
      formData.append('data[print_price][0][base_price]', this.state.setPriceGry ? "false" : "true");
      formData.append('data[print_price][1][page_size]', this.state.selectedPage);
      formData.append('data[print_price][1][paper_type]', this.state.selectedPageType);
      formData.append('data[print_price][1][colors]', 'colored');
      formData.append('data[print_price][1][single_side_price]', this.state.valueClr.toString());
      formData.append('data[print_price][1][double_side_price]', '0');
      formData.append('data[print_price][1][base_price]', this.state.setPriceClr ? "false" : "true");
    } else if (this.state.print_colours.includes("greyscale")) {
      formData.append('data[print_price][0][page_size]', this.state.selectedPage);
      formData.append('data[print_price][0][paper_type]', this.state.selectedPageType);
      formData.append('data[print_price][0][colors]', 'greyscale');
      formData.append('data[print_price][0][single_side_price]', this.state.valueGry.toString());
      formData.append('data[print_price][0][double_side_price]', '0');
      formData.append('data[print_price][0][base_price]', this.state.setPriceGry ? "false" : "true");
    } else if (this.state.print_colours.includes("colored")) {
      formData.append('data[print_price][0][page_size]', this.state.selectedPage);
      formData.append('data[print_price][0][paper_type]', this.state.selectedPageType);
      formData.append('data[print_price][0][colors]', 'colored');
      formData.append('data[print_price][0][single_side_price]', this.state.valueClr.toString());
      formData.append('data[print_price][0][double_side_price]', '0');
      formData.append('data[print_price][0][base_price]', this.state.setPriceClr ? "false" : "true");
    }

    //formData.append('token', this.state.authToken);


    this.setState({ loading: true });

    const header = {
      "accept": "application/json",
      uuid: await StorageProvider.get('login_type') === 'guest_account' ? this.state.authToken : ""

    };

    this.setState({ loading: true });
    this.props.showLoader();


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostMerchantPriceCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.postMerchantPrintPrices}`)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

}
